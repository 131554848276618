import { Box } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { BaseBtn, Icon, Row } from 'shared/ui';
import { addPicture } from '../../assets';
interface PhotoData {
  data_url: string;
}
interface Props {
  siteId: string;
  photoList: PhotoData[];
}

export const PhotoEdit = ({ siteId, photoList }: Props) => {
  const [images, setImages] = useState([]);
  const [changedImages, setChangedImages] = useState(false);
  const maxNumber = 5;

  useEffect(() => {
    setImages(photoList);
  }, [photoList]);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    setChangedImages(true);
    console.log(imageList, addUpdateIndex);
  };
  const saveImages = () => {};
  return (
    <Box className="App" sx={{ marginTop: '16px', marginBottom: '16px' }}>
      <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
        {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
          // <Box className="upload__image-wrapper">
          //   <button style={isDragging ? { color: 'red' } : undefined} onClick={onImageUpload} {...dragProps}>
          //     Click or Drop here
          //   </button>
          //   &nbsp;
          //   <button onClick={onImageRemoveAll}>Remove all images</button>
          //   {imageList.map((image, index) => (
          //     <div key={index} className="image-item">
          //       <img src={image['data_url']} alt="" width="100" />
          //       <div className="image-item__btn-wrapper">
          //         <button onClick={() => onImageUpdate(index)}>Update</button>
          //         <button onClick={() => onImageRemove(index)}>Remove</button>
          //       </div>
          //     </div>
          //   ))}
          // </Box>
          <Box sx={{ maxWidth: '100%', overflowY: 'scroll' }}>
            <Row spacing={'20px'} sx={{ padding: '4px' }}>
              {imageList.length < 5 && (
                <Box
                  onClick={onImageUpload}
                  sx={{
                    minWidth: '100px',
                    minHeight: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#F8F9FA',
                    boxShadow: '0px 1px 3px 0px #0000001A, 0px 1px 2px 0px #0000001A',
                    borderRadius: '8px',
                  }}
                >
                  <Icon sx={{ mx: '8px' }} src={addPicture} />
                </Box>
              )}
              {imageList.map((image, index) => (
                <Box
                  key={index}
                  className="image-item"
                  sx={{
                    minWidth: '100px',
                    maxWidth: '100px',
                    height: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#F8F9FA',
                    boxShadow: '0px 1px 3px 0px #0000001A, 0px 1px 2px 0px #0000001A',
                    borderRadius: '8px',
                  }}
                >
                  <img
                    style={{ padding: '4px', maxWidth: '100%', maxHeight: '100%' }}
                    height="auto"
                    width="auto"
                    src={image['data_url']}
                    alt=""
                  />
                  {/* <div className="image-item__btn-wrapper">
                    <button onClick={() => onImageUpdate(index)}>Update</button>
                    <button onClick={() => onImageRemove(index)}>Remove</button>
                  </div> */}
                </Box>
              ))}
            </Row>
          </Box>
        )}
      </ImageUploading>
    </Box>
  );
};
