import { Box, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { useAppSelector } from 'shared/hooks';

import { usePlacesWidget } from 'react-google-autocomplete';
import { MobileInput } from '../../ui';
import { t } from 'i18next';
import { position } from '../../assets';

interface Props {
  onChangeCoordinates: ({ lat, lng }: { lat: number; lng: number }) => void;
  getCoordinates: () => void;
  value: { lat: number; lng: number };
  mapKey: string;
}

export const ISSAutocomplete = ({ onChangeCoordinates, getCoordinates, value, mapKey }: Props) => {
  const [positionName, setPositionName] = useState<string>('');

  const { ref } = usePlacesWidget({
    apiKey: mapKey,
    onPlaceSelected: (place) => {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      onChangeCoordinates({ lat, lng });
      setPositionName(place.formatted_address);
    },
  });

  const blurInput = () => {};

  const onChange = (value: string) => {
    setPositionName(value);
  };

  return (
    <Box>
      <MobileInput
        label={`${t('position_edit_site')}`}
        name="position"
        error={false}
        helperText={''}
        onChange={(value) => onChange(value.target.value)}
        blur={blurInput}
        value={positionName}
        inputRef={ref}
        search
        placeholder={''}
        endAdornment={
          <InputAdornment position="start" onClick={getCoordinates}>
            <Box component="img" src={position} />
          </InputAdornment>
        }
      />
    </Box>
  );
};
