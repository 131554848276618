
import { TranslateColumn } from 'components/BaseTable';
import { TimeZone } from '../timeZoneModels';

export type TableTitle = { title: string; key: keyof TimeZone; width: number | string };

export const TABLE_TITLES: TableTitle[] = [
	{ title: 'display_name', key: 'displayName', width: '100%' },
	{ title: 'has_dst', key: 'hasDST', width: '50%' },
	{ title: 'utc_offset', key: 'utcOffset', width: '50%' },
	{ title: 'zone_name', key: 'zoneName', width: '100%' },
	{ title: 'zone_start', key: 'zoneStart', width: '100%' },
	{ title: 'zone_end', key: 'zoneEnd', width: '100%' },
	{ title: 'dst', key: 'dst', width: '50%' },
	{ title: 'is_active', key: 'isActive', width: '50%' },
	{ title: 'is_sync', key: 'isSync', width: '50%' }
];
export const TRANSLATE_COLUMNS: TranslateColumn<TimeZone>[] = [
	{ key: 'hasDST', additionalKey: '' },
	{ key: 'dst', additionalKey: '_time_zone_table' },
	{ key: 'isActive', additionalKey: '' },
	{ key: 'isSync', additionalKey: '' },
]


