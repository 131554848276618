import { Box, InputAdornment } from '@mui/material';
import { BaseInput } from 'shared/ui';
import { SxProps } from '@mui/system';
import { FC, MutableRefObject } from 'react';
import { searchIcon } from '../../assets';

type Props = {
  label: string;
  name: string;
  error: boolean;
  helperText: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  blur?: (value: string) => void;
  value: string | number;
  sx?: SxProps;
  type?: string;
  valueForNumberChanging?: number;
  inputRef?: MutableRefObject<HTMLInputElement>;
  search?: boolean;
  endAdornment?: React.ReactNode;
  placeholder?: string;
};
export const MobileInput: FC<Props> = ({ sx = {}, search, endAdornment, ...props }) => {
  return (
    <BaseInput
      sx={{
        borderRadius: '4px',
        width: '100%',
        height: '40px',
        ...sx,
      }}
      endAdornment={endAdornment}
      startAdornment={
        search ? (
          <InputAdornment position="start">
            <Box component="img" src={searchIcon} />
          </InputAdornment>
        ) : (
          <></>
        )
      }
      {...props}
    />
  );
};
