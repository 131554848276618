import { Box } from '@mui/material';
import { Row, Text } from '../../../../shared/ui';
import { useExtendedTranslation } from 'shared/hooks';
import { LoginForm } from '../Components/';

export const ISSLoginPage = () => {
  const { t } = useExtendedTranslation();
  return (
    <Box sx={{ p: { xs: '18px 31px', xl: '58px 61px' } }}>
      <Text
        sx={{
          color: 'primary.main',
          fontFamily: 'Noto Sans Hebrew',
          mb: { xs: '15px', xl: '50px' },
          fontSize: { xs: '41px', xl: '64px' },
          lineHeight: { xs: '46px', xl: '96px' },
          textAlign: 'center',
        }}
      >
        {t('irrigation_site_survey_header_text')}
      </Text>
      <Row justifyContent="center">
        <LoginForm />
      </Row>
    </Box>
  );
};
