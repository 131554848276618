import useAxios from 'app/useAxios';
import { useTranslation } from 'react-i18next';
import { useToast } from 'shared/hooks';

type ConfigurationData = {
  timeZoneID: number;
};

export const useConfiguration = () => {
  const { loading, request } = useAxios();
  const { showError } = useToast();
  const { t } = useTranslation();

  return {
    getTimeZone: () =>
      request<undefined, { data: { timeZoneID: string }; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/configuration/timeZone`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            return true;
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    updateTimeZone: (data: ConfigurationData) =>
      request<undefined, { succeeded: boolean }>({
        method: 'put',
        showSuccessMessageCode: undefined,
        url: `/configuration`,
        payload: data,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            return true;
          } else {
            showError(t('update_failed_due_to_an_error'));
          }
        },
      }),

    loading,
    isLoadingTimezone: loading,
  };
};
