import { QuantityAndPrice } from '../../Components/MaterialsAndPricingTable';
import { SiteMaterials } from '../../ISSModels';

export const compareMaterials = (siteMaterials: SiteMaterials[], localSiteMaterials: QuantityAndPrice[]) => {
  const siteMaterialsMap = new Map(siteMaterials.map((item) => [item.id, item]));

  const quantityDifferences = [];
  const newItems = [];

  localSiteMaterials.forEach((localItem) => {
    const siteItem = siteMaterialsMap.get(localItem.id);

    if (siteItem) {
      if (siteItem.quantity !== localItem.quantity) {
        quantityDifferences.push({
          id: localItem.id,
          siteQuantity: siteItem.quantity,
          localQuantity: localItem.quantity,
        });
      }
    } else {
      newItems.push(localItem);
    }
  });

  return {
    quantityDifferences,
    newItems,
  };
};
