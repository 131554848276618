import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { BaseBtn, Row, Text } from '../../../../shared/ui';
import { t } from 'i18next';
import { BaseSelect } from 'features/BaseSelect';
import { useEffect, useMemo, useState } from 'react';
import { useISSData } from '../hooks';
import { useAppSelector } from 'shared/hooks';
import { useNavigate } from 'react-router-dom';

export const MaterialListManagement = () => {
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [selectedSite, setSelectedSite] = useState<string>('');
  const { getISSProjects, getISSSites, getMaterialLists } = useISSData();
  const { ISSProjects, ISSSites, materialLists } = useAppSelector((st) => st.ISSSlice);
  const onChangeProject = (id: string) => {
    setSelectedProject(id);
    getISSSites(id);
  };
  const onChangeSite = (id: string) => {
    setSelectedSite(id);
  };
  useEffect(() => {
    getISSProjects();
    getMaterialLists();
  }, []);
  const push = useNavigate();
  const clickBack = () => {
    push('/mobile/iss');
  };
  const projectItems = useMemo(
    () =>
      ISSProjects.map((s) => ({
        value: s.id.toString(),
        label: s.projectName,
      })),
    [ISSProjects]
  );
  const siteItems = useMemo(
    () =>
      ISSSites.map((s) => ({
        value: s.id.toString(),
        label: s.siteName,
      })),
    [ISSSites]
  );
  return (
    <Box sx={{ p: { xs: '18px 31px', xl: '58px 61px' } }}>
      <Text
        sx={{
          color: 'primary.main',
          fontFamily: 'Noto Sans Hebrew',
          mb: { xs: '15px', xl: '50px' },
          fontSize: { xs: '41px', xl: '64px' },
          lineHeight: { xs: '46px', xl: '96px' },
          textAlign: 'center',
        }}
      >
        {t('material_list_management_header_text')}
      </Text>
      <BaseBtn btnColor="primary.dark" onClick={clickBack}>
        {t('back')}
      </BaseBtn>
      <Box>
        <BaseSelect
          value={selectedProject}
          onChange={onChangeProject}
          items={projectItems}
          hideAllButton
          hideValue
          controlsWidth={200}
          noSelectLabel={`${t('select_project_iss_page')}`}
          sx={{ width: '200px', mb: '16px' }}
        />
        {selectedProject && (
          <Row>
            <BaseSelect
              value={selectedSite}
              onChange={onChangeSite}
              items={siteItems}
              hideAllButton
              hideValue
              controlsWidth={200}
              noSelectLabel={`${t('select_site_iss_page')}`}
              sx={{ width: '200px', mb: '16px' }}
            />
          </Row>
        )}
      </Box>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Material List Format ID</TableCell>
              <TableCell>Material Code</TableCell>
              <TableCell>Long Description</TableCell>
              <TableCell>Short Description</TableCell>
              <TableCell>Unit Type</TableCell>
              <TableCell>Unit Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {materialLists.map((item) => (
              <TableRow key={`key-${item.id}`}>
                <TableCell sx={{ height: '30px', padding: '0' }}>{item.id}</TableCell>
                <TableCell sx={{ height: '30px', padding: '0' }}>{item.materialListFormatID}</TableCell>
                <TableCell sx={{ height: '30px', padding: '0' }}>{item.materialCode}</TableCell>
                <TableCell sx={{ height: '30px', padding: '0' }}>{item.longDescription || 'N/A'}</TableCell>
                <TableCell sx={{ height: '30px', padding: '0' }}>{item.shortDescription || 'N/A'}</TableCell>
                <TableCell sx={{ height: '30px', padding: '0' }}>{item.unitType || 'N/A'}</TableCell>
                <TableCell sx={{ height: '30px', padding: '0' }}>{item.unitCost}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
