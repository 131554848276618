import { useAppDispatch, useToast } from 'shared/hooks';

import useAxios from 'shared/api/useAxios';
import {
  ISSPhoto,
  ISSProject,
  ISSSite,
  ISSSiteChanges,
  ISSSiteCreation,
  MaterialLists,
  SiteMaterials,
} from 'features/Mobile/ISS/ISSModels';
import {
  setISSProjects,
  setISSSites,
  setISSPhotos,
  setMaterialLists,
  setSiteMaterials,
} from 'features/Mobile/ISS/ISSSlice';
import { t } from 'i18next';

export const useISSData = () => {
  const dispatch = useAppDispatch();
  const { loading, request } = useAxios();
  const { showSuccess } = useToast();
  return {
    getISSProjects: () =>
      request<undefined, { data: ISSProject[] }>({
        method: 'get',
        url: `/iss/projects`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setISSProjects(data?.data));
        },
      }),
    getISSSites: (projectId: string) =>
      request<undefined, { data: ISSSite[] }>({
        method: 'get',
        url: `/iss/sites?projectId=${projectId}`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setISSSites(data?.data));
        },
      }),
    getISSPhotos: (siteId: string) =>
      request<undefined, { data: ISSPhoto[] }>({
        method: 'get',
        url: `/iss/photos?siteId=${siteId}`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setISSPhotos(data?.data));
        },
      }),
    updateISSPhotos: (siteId: string) =>
      request<undefined, { data: ISSPhoto[] }>({
        method: 'get',
        url: `/iss/photos?siteId=${siteId}`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setISSPhotos(data?.data));
        },
      }),
    addSite: (creationData: ISSSiteCreation) =>
      request<undefined, { data: ISSPhoto[] }>({
        method: 'post',
        url: `/iss/site`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          showSuccess(t('site_successfully_added'));
          // dispatch(setISSPhotos(data?.data));
        },
        payload: creationData,
      }),
    editSite: (creationData: { siteID: number; changes: ISSSiteChanges }) =>
      request<undefined, { data: ISSPhoto[] }>({
        method: 'put',
        url: `/iss/site`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          // dispatch(setISSPhotos(data?.data));
        },
        payload: creationData,
      }),
    getMaterialLists: () =>
      request<undefined, { data: MaterialLists[] }>({
        method: 'get',
        url: `/iss/materialLists`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setMaterialLists(data?.data));
        },
      }),
    getSiteMaterialLists: (siteId: string) =>
      request<undefined, { data: SiteMaterials[] }>({
        method: 'get',
        url: `/iss/siteMaterials?siteId=${siteId}`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          dispatch(setSiteMaterials(data?.data));
        },
      }),
    isLoadingFota: loading,
  };
};
