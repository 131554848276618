import ClickAwayListener from '@mui/base/ClickAwayListener';
import { PlayArrow } from '@mui/icons-material';
import { Box, FormControl, IconButton, IconButtonProps, RadioGroup, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { AssignedCustomer } from 'features/WeatherStationsManager/WeatherStationModels';
import { useWeatherStationsManager } from 'features/WeatherStationsManager/hooks/api';
import List from 'rc-virtual-list';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { clear_filter, home } from 'shared/assets';
import { useAnchorEl, useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { BaseBtn, Icon, Row, SearchInput, Text } from 'shared/ui';
import { CustomFormControlLabel } from 'shared/ui/CustomFormControlLabel';
import { DeleteCustomer } from '../DeleteCustomer';
interface ResetButtonProps extends IconButtonProps {
  showHomeButton?: boolean;
}

const ResetButton: FC<ResetButtonProps> = ({ onClick, sx, showHomeButton }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: 'absolute',
      zIndex: 2,
      height: '29px',
      width: '33px',
      ...sx,
    }}
  >
    <Icon src={showHomeButton ? home : clear_filter} />
  </IconButton>
);

interface Props {
  value: string;
  onChange: (value: string) => void;
  objectID: number;
  objectName: string;
  withConfirm?: boolean;
  defaultValue?: string;
  sx?: SxProps;
  formInput?: boolean;
  disabled?: boolean;
  hideValue?: boolean;
  controlsWidth?: number;
  withoutMargin?: boolean;
}

export const CustomerListEditable: FC<Props> = ({
  sx,
  formInput,
  value,
  onChange,
  defaultValue,
  disabled,
  hideValue,
  controlsWidth,
  withoutMargin,
  objectID,
  objectName,
}) => {
  const { t } = useExtendedTranslation();
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const [innerValue, setInnerValue] = useState<string>('');
  const [valueItems, setValueItems] = useState<AssignedCustomer[]>();
  const [filterItems, setfilterItems] = useState<AssignedCustomer[]>();

  const [customerForDelete, setCustomerForDelete] = useState();

  const { getAssignedCustomers } = useWeatherStationsManager();
  const { assignedCustomers } = useAppSelector((st) => st.weatherStation);
  const handleChange = (value: string) => {
    onChange?.(value);
    onCloseMenu();
  };
  const { palette } = useTheme();

  useEffect(() => {
    getAssignedCustomers(objectID);
  }, []);
  useEffect(() => {
    setValueItems(assignedCustomers);
    if (assignedCustomers && assignedCustomers.length) {
      const mainCustomer = assignedCustomers.find((item) => item.isMainCustomer);
      onChange(mainCustomer.customerID.toString());
    }
  }, [assignedCustomers]);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const currentFilter = useMemo(
    () => valueItems && valueItems.find((item) => item.customerID.toString() === value),
    [value, valueItems]
  );
  const [search, setSearch] = useState('');
  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  useEffect(() => {
    if (valueItems && valueItems.length) {
      setfilterItems(
        valueItems.filter((i) => {
          const findByLabel = i.customerName.toLowerCase().includes(search.toLowerCase().trim());
          const findByValue = i.customerID.toString().toLowerCase().includes(search.toLowerCase().trim());
          return findByLabel || findByValue;
        })
      );
    }
  }, [search, valueItems]);
  const handleClose = () => {
    onCloseMenu();
  };
  const toggleMenu = (e) => {
    isOpen ? handleClose() : onOpenMenu(e);
  };

  const homeButtonClick = () => {
    // if (defaultValue) {
    // 	handleChange(defaultValue);
    // }

    const mainCustomer = valueItems.find((item) => item.isMainCustomer);
    handleChange(mainCustomer.customerID.toString());
  };

  const isShowHomeButton = () => {
    const customer = valueItems.find((item) => item.customerID.toString() === value);
    if (!customer) {
      return false;
    }
    return !customer.isMainCustomer;
  };
  const tryToDelete = (customer) => {
    setCustomerForDelete(customer);
  };
  const onCloseDeleteCustomerModal = () => {
    setCustomerForDelete(null);
  };
  return (
    <>
      {valueItems && valueItems.length ? (
        <Box sx={{ mr: withoutMargin ? '' : '20px', ...sx }}>
          <Text
            noWrap
            sx={{
              color: 'primary.dark',
              fontSize: '14px',
              fontWeight: 600,
              pr: '2px',
              mt: '4px',
              mb: '2px',
            }}
          >
            {t('customer_name_meteorological_station_modal')}
          </Text>

          <Row
            position="relative"
            sx={{
              justifyContent: value !== '' && value && !isNaN(parseInt(value)) ? 'space-between' : '',
            }}
          >
            {/* showHomeButton */}
            {isShowHomeButton() && <ResetButton showHomeButton onClick={homeButtonClick} />}

            <BaseBtn
              btnColor="info.main"
              disabled={disabled}
              onClick={toggleMenu}
              sx={{
                borderColor: formInput ? palette.info.dark : 'primary.dark',
                opacity: '1 !important',
                height: '29px',
                minWidth: '100%',
                px: 0,
                bgcolor: disabled ? 'info.contrastText' : 'info.main',
              }}
            >
              <Text
                noWrap
                sx={{
                  color: disabled ? 'rgba(0, 0, 0, 0.38);' : 'primary.dark',
                  fontSize: '14px',
                  fontWeight: 500,
                  width: 'calc(100% - 40px)',
                  pl: isShowHomeButton() ? '35px' : '10px',
                  textAlign: 'left',
                  fontFamily: 'Noto Sans Hebrew !@important',
                }}
              >
                {currentFilter?.customerID ? (
                  <>
                    {!hideValue && (
                      <>
                        <span> {currentFilter?.customerName}</span>
                        {` - `}
                      </>
                    )}
                    <span>{currentFilter?.customerID}</span>
                  </>
                ) : (
                  <Box component="span" sx={{ color: '#d3d3d3 !important' }}>
                    {t('display_all_filter_base_select')}
                  </Box>
                )}
              </Text>

              <PlayArrow
                sx={{
                  m: '7px',
                  fontSize: '14px',
                  color: formInput ? palette.info.dark : 'primary.dark',
                  transform: `rotate(${isOpen ? '-90deg' : '90deg'})`,
                }}
              />
            </BaseBtn>
          </Row>

          {isOpen && (
            <ClickAwayListener onClickAway={handleClose}>
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: '20',
                  background: 'white',
                  width: '300px',
                  borderTop: '1px solid grey',
                  borderColor: 'rgba(255, 255, 255, 1)',
                  borderRadius: '10px',
                  boxShadow:
                    '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
                  mt: '5px',
                  '& .MuiPaper-root': {
                    borderRadius: '12px',
                  },
                }}
              >
                <Box>
                  <SearchInput
                    onfocus
                    value={search}
                    onChange={handleChangeSearch}
                    placeholder={`${t('filter_search_placeholder')}`}
                    noBorder
                  />
                  <Box
                    sx={{
                      borderTop: '1px solid grey',
                      borderColor: 'info.dark',
                    }}
                  >
                    {filterItems.length === 0 ? (
                      <Text sx={{ color: 'primary.main', textAlign: 'center', my: '10px' }}>
                        {t('filter_no_items')}
                      </Text>
                    ) : (
                      <FormControl sx={{ width: controlsWidth ? `${controlsWidth}px` : '100%' }}>
                        <RadioGroup value={value} sx={{ width: '100%' }}>
                          <>
                            <Box
                              sx={{
                                maxHeight: '500px',
                              }}
                            >
                              {filterItems && filterItems.length && (
                                <List data={filterItems} height={401} itemHeight={33} itemKey="id">
                                  {(item) =>
                                    item.isMainCustomer ? (
                                      <CustomFormControlLabel
                                        sx={{
                                          color: 'primary.dark',
                                          fontWeight: '700',
                                        }}
                                        hideValue={hideValue}
                                        onChange={() => handleChange(item.customerID.toString())}
                                        value={item.customerID.toString()}
                                        labels={{ value: item.customerID.toString(), label: item.customerName }}
                                      />
                                    ) : (
                                      <CustomFormControlLabel
                                        hideValue={hideValue}
                                        onChange={() => handleChange(item.customerID.toString())}
                                        value={item.customerID.toString()}
                                        labels={{ value: item.customerID.toString(), label: item.customerName }}
                                        showDeleteIcon
                                        tryToDelete={() =>
                                          tryToDelete({
                                            value: item.customerID.toString(),
                                            label: item.customerName,
                                            isMainCustomer: false,
                                          })
                                        }
                                      />
                                    )
                                  }
                                </List>
                              )}
                            </Box>
                          </>
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </ClickAwayListener>
          )}
        </Box>
      ) : (
        <></>
      )}

      <DeleteCustomer
        openModal={!!customerForDelete}
        onCloseModal={onCloseDeleteCustomerModal}
        customer={customerForDelete}
        controllerName={objectName}
      />
    </>
  );
};
