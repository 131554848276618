import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'shared/hooks';
import { useNavigate } from 'react-router-dom';
import { useISSData } from '../../hooks';
import { BaseBtn, Row, Text } from '../../../../../shared/ui';
import { t } from 'i18next';

export const EditSiteMaterial = () => {
  const { getISSProjects, getMaterialLists } = useISSData();
  const { ISSProjects, ISSSites, materialLists, siteMaterials } = useAppSelector((st) => st.ISSSlice);

  useEffect(() => {
    getISSProjects();
    getMaterialLists();
  }, []);

  return (
    <Box sx={{ p: { xs: '18px 31px', xl: '58px 61px' } }}>
      <Text
        sx={{
          color: 'primary.main',
          fontFamily: 'Noto Sans Hebrew',
          mb: { xs: '15px', xl: '50px' },
          fontSize: { xs: '41px', xl: '64px' },
          lineHeight: { xs: '46px', xl: '96px' },
          textAlign: 'center',
        }}
      >
        {t('report_management_header_text')}
      </Text>
      222
    </Box>
  );
};
