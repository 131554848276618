import { Box } from '@mui/material';
import { BaseInput } from 'shared/ui';
import { SxProps } from '@mui/system';
import { FC, MutableRefObject } from 'react';
import { BaseSelect } from 'features/BaseSelect';

type Props = {
  title?: string;
  onChange?: (value: string) => void;
  value?: string;
  sx?: SxProps;
  type?: string;
  valueForNumberChanging?: number;
  inputRef?: MutableRefObject<HTMLInputElement>;
  items: { value: string; label: string }[];
  noSelectLabel?: string;
  translate?: boolean;
};
export const MobileSelect: FC<Props> = ({
  sx = {},
  value,
  onChange,
  noSelectLabel,
  items,
  translate,
  title,
  ...props
}) => {
  return (
    <BaseSelect
      sx={{
        borderRadius: '4px',
        width: '100%',
        ...sx,
      }}
      sxBtn={{
        borderRadius: '4px',
        height: '40px',
        borderColor: 'primary.dark',
      }}
      title={title}
      value={value}
      onChange={onChange}
      items={items}
      hideAllButton
      noSelectLabel={noSelectLabel}
      hideValue
      formInput
      withoutMargin
      translate={translate}
      {...props}
    />
  );
};
