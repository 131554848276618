import { Box } from '@mui/material';
import { ValvesReportView } from 'features/ControllerManager/controllerModels';
import { t } from 'i18next';
import { FC, useEffect, useRef, useState } from 'react';
import localStorageService from 'shared/localStorage';
import uPlot, { AlignedData, Series } from 'uplot';
import UplotReact from 'uplot-react';
import 'uplot/dist/uPlot.min.css';
import { usePlugins } from './hooks/usePlugins';
import { WEEK_DAY_NAMES_ENG, WEEK_DAY_NAMES_HE } from './Constants';
import { BigTitle } from 'shared/ui';
//import { seriesBarsPlugin } from './seriesBarsPlugin';
type ChartTypes = 'linear' | 'bars';
type ChartColors = {
  stroke: string;
  fill: string;
};
interface Props {
  data: any[];

  startDate: string;
  endDate: string;
  height?: number;
  popupPadding: number;
  chartTypes: ChartTypes[];
  chartColors: ChartColors[];
  leftLabel?: string;
  rightLabel?: string;
  pointTextPosition: number;
  showThirdScale?: boolean;
  minMaxYScales?: number[];
  yOffset?: number;
  ySecondOffset?: number;
}

export const LineBarChart: FC<Props> = ({
  data,
  startDate,
  endDate,
  height,
  popupPadding,
  chartTypes,
  chartColors,
  leftLabel,
  rightLabel,
  pointTextPosition,
  showThirdScale,
  minMaxYScales,
  yOffset,
  ySecondOffset,
}) => {
  const { isRTL } = localStorageService;

  const [defaultScaleXParams, setDefaultScaleXParams] = useState({ min: 0, max: 0 });

  useEffect(() => {
    const date1 = new Date(startDate + 'T00:00:00');
    const date2 = new Date(endDate + 'T00:00:00');

    // add 1 day
    date2.setDate(date2.getDate() + 1);
    date2.setHours(0, 0, 0, 0);
    const min = Math.floor(date1.getTime() / 1000);
    const max = Math.floor(date2.getTime() / 1000);

    setDefaultScaleXParams({ min, max });
    if (chartRef.current) {
      const chart = chartRef.current;
      chart.setScale('x', { min, max });
    }
  }, [startDate, endDate]);

  const { chartSize, getSize, setChartSize } = usePlugins();
  useEffect(() => {
    setChartSize(getSize(popupPadding, height));
  }, [popupPadding, height]);
  const bars = (u, seriesIdx, idx0, idx1) => {
    const { bars } = uPlot.paths;
    const s = u.series[seriesIdx];
    let style = s.drawStyle;
    const interp = s.lineInterpolation;

    const renderer = (style = bars({ size: [15, 15] }));

    return renderer(u, seriesIdx, idx0, idx1);
  };

  const linear = (u, seriesIdx, idx0, idx1) => {
    const { linear } = uPlot.paths;
    const s = u.series[seriesIdx];
    const style = s.drawStyle;
    const interp = s.lineInterpolation;
    const _linear = linear();
    const renderer = _linear;

    return renderer(u, seriesIdx, idx0, idx1);
  };

  const graphData = data;

  // const customGridSplits = (uplot: uPlot, axisIdx, scaleMin, scaleMax, foundIncr, foundSpace, extraArg) => {
  // 	const result = Array(COUNT_OF_Y_LINES).fill(null).map((v, i) => {
  // 		if (i < COUNT_OF_Y_LINES - 16) {
  // 			return null;
  // 		}
  // 		return axisIdx[i] || 0;
  // 	});

  // 	return result;
  // }
  const dayTimeAndYear = isRTL ? `{HH}:{mm}\n{WWW} {DD}.{M}.{YYYY} ` : `{HH}:{mm}\n{DD}.{M}.{YYYY} {WWW}`;
  const dayAndYear = isRTL ? `\n{WWW} {DD}.{M}.{YYYY} ` : `\n{DD}.{M}.{YYYY} {WWW}`;
  const everySecondLabel = (u, vals, space) => {
    return vals.map((val, i) => (i % 2 === 0 ? uPlot.fmtDate('{HH}:{mm}', val) : ''));
  };
  const options: any = {
    ...chartSize,

    scales: {
      x: {
        range(u, min, max) {
          return [u.data[0][0], u.data[0][u.data[0].length - 1]];
        },
        time: true,

        // range: [defaultScaleXParams.min, defaultScaleXParams.max],
        // min: defaultScaleXParams.min,
        // max: defaultScaleXParams.max,
      },
      y: {
        range(u, min, max) {
          let minY = 0;
          let maxY = max;
          if (minMaxYScales) {
            minY = minMaxYScales[0];
            maxY = minMaxYScales[1];
          }
          maxY = yOffset ? yOffset + maxY : maxY;

          return [minY, maxY];
        },
      },
      rain: {
        time: false,
        range(u, min, max) {
          let maxY = max;
          maxY = ySecondOffset ? ySecondOffset + maxY : maxY;

          return [0, maxY];
        },
        // max: defaultScaleXParams.max,
      },
    },
    fmtDate: (tpl) => uPlot.fmtDate(tpl, isRTL ? WEEK_DAY_NAMES_HE : WEEK_DAY_NAMES_ENG), //future for translate
    axes: [
      {
        space: 40,
        stroke: 'black',
        font: `10px `,
        //	labelFont: `12px 'Roboto'`,
        grid: {
          show: false,
        },
        values: [[31536000, '{D}.{M}', null, null, null, null, null, null, 1]],

        ticks: {
          stroke: '#c7d0d9',
          // filter: (val, i, all) => {
          //   return false;
          // },
        },
        side: 2,
      },
      {
        space: 70,
        stroke: 'black',
        //	font: `12px 'Roboto'`,
        //	labelFont: `12px 'Roboto'`,
        grid: {
          stroke: '#c7d0d9',
        },
        ticks: {
          stroke: '#c7d0d9',
        },
        scale: 'y',
      },
      {
        // filter: (u, i, all) => {
        // 	const maxValue = u.scales["flow_rate"].max
        // 	const viewMaxValue = maxValue / 135 * 100
        // 	return i.filter(item => item < viewMaxValue)
        // },
        show: !!showThirdScale,
        stroke: 'black',
        scale: showThirdScale ? 'rain' : null,
        labelFont: '14px Noto Sans Hebrew',
        side: 1,
        ticks: {
          stroke: '#c7d0d9',
        },
        grid: { show: false },
      },
    ],
    series: [
      {
        label: 'X',
      },
      Object.assign({
        label: 'Y',
        drawStyle: 2,
        lineInterpolation: null,
        stroke: chartColors[0]?.stroke,
        fill: chartColors[0]?.fill,
        paths: chartTypes[0] == 'bars' ? bars : linear,
        scale: 'y',
      }),
      Object.assign({
        drawStyle: 2,
        lineInterpolation: null,
        stroke: chartColors[1]?.stroke,
        fill: chartColors[1]?.fill,
        paths: chartTypes[1] == 'bars' ? bars : linear,
        scale: showThirdScale ? 'rain' : 'y',
      }),
    ],
    legend: {
      show: false,
    },
    splits: (v: uPlot) => {
      return v;
    },
    padding: [10, 85, 10, 40],

    //series,
    hooks: {
      drawSeries: [],
      draw: [
        (u) => {
          const ctx = u.ctx;
          ctx.font = 'bold 10px Noto Sans Hebrew';

          const formatValue = (value) => (value % 1 === 0 ? value.toFixed(0) : value.toFixed(2));
          const drawValue = (value, x, y, color = 'black') => {
            ctx.fillStyle = color;
            ctx.fillText(formatValue(value), x, y);
          };

          u.data[1].forEach((value, idx) => {
            if (!value) return;
            const x = u.valToPos(u.data[0][idx], 'x', true);
            const y = u.valToPos(value, 'y', true);

            drawValue(value, x + pointTextPosition, y - 8); // Розташування трохи вище точки
          });
          u.data[2].forEach((value, idx) => {
            if (!value) return;

            const x = u.valToPos(u.data[0][idx], 'x', true);

            const scaleForText = showThirdScale ? 'rain' : 'y';
            const y = u.valToPos(value, scaleForText, true);
            drawValue(value, x + pointTextPosition, y - 8, '#0099ff');
          });
        },
      ],
    },
    plugins: [
      // seriesBarsPlugin({
      //   ori: 0,
      //   dir: 1,
      //   radius: 0.5,
      //   disp: {
      //     stroke: {
      //       unit: 3,
      //       values: (u, seriesIdx) => u.data[2].map((v) => (v == 0 ? '#33BB55' : v == 1 ? '#F79420' : '#BB1133')),
      //     },
      //     fill: {
      //       unit: 3,
      //       values: (u, seriesIdx) => u.data[2].map((v) => (v == 0 ? '#33BB55A0' : v == 1 ? '#F79420A0' : '#BB1133A0')),
      //     },
      //   },
      // }),
      // seriesBarsPlugin({
      // 	ori: 0,
      // 	dir: 1,
      // 	radius: 0.5,
      // 	disp: {
      // 		stroke: {
      // 			unit: 3,
      // 			values: (u, seriesIdx) => u.data[2].map(v =>
      // 				v == 0 ? "#33BB55" :
      // 					v == 1 ? "#F79420" :
      // 						"#BB1133"
      // 			),
      // 		},
      // 		fill: {
      // 			unit: 3,
      // 			values: (u, seriesIdx) => u.data[2].map(v =>
      // 				v == 0 ? "#33BB55A0" :
      // 					v == 1 ? "#F79420A0" :
      // 						"#BB1133A0"
      // 			),
      // 		}
      // 	}
      // }),
    ],
  };

  const chartRef = useRef(null);

  return (
    <>
      {data && data[0]?.length ? (
        <Box sx={{ position: 'relative', overflow: 'hidden', width: '100%' }}>
          {leftLabel && (
            <Box
              sx={{
                position: 'absolute',
                right: isRTL ? '0px' : 'auto',
                left: isRTL ? 'auto' : '0px',
                bottom: 20,
                height: height,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
              }}
            >
              <Box sx={{}}>
                <Box sx={{ transform: 'rotate(-90deg)' }}>{leftLabel}</Box>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              position: 'relative',
              display: 'block',
              height: height,
              width: '100px',
              '.u-cursor-pt': {
                //borderRadius: '0 !important'
                opacity: '0',
              },
              '.u-over': {
                overflow: 'hidden',
                borderBottom: '1px solid #666',
                borderLeft: '1px solid #666',
                borderRight: '1px solid #666',
              },
              '.u-cursor-x, .u-cursor-y': {
                borderRight: '2px dashed rgb(90, 147, 211)',
                borderLeft: '2px dashed rgb(90, 147, 211)',
              },
            }}
          >
            {popupPadding && (
              <Box
                sx={{
                  position: 'relative',
                  display: 'block',
                  height: height,
                  width: '100%',
                  right: 30,
                  top: 0,
                  opacity: 1,
                }}
              >
                <UplotReact
                  options={options}
                  data={graphData}
                  onCreate={(chart) => {
                    chartRef.current = chart;
                  }}
                  onDelete={(chart) => {
                    chartRef.current = null;
                  }}
                />
              </Box>
            )}
          </Box>
          {rightLabel && (
            <Box
              sx={{
                position: 'absolute',
                left: isRTL ? '0px' : 'auto',
                right: isRTL ? 'auto' : '0px',
                bottom: 20,
                height: height,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
              }}
            >
              <Box sx={{}}>
                <Box sx={{ transform: 'rotate(-90deg)' }}>{rightLabel}</Box>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box justifyContent="center" alignItems="center" sx={{ margin: 'auto', height: '300px', display: 'flex' }}>
          <BigTitle align="center">{t('no_data_line_bar_chart')}</BigTitle>
        </Box>
      )}
    </>
  );
};
