import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  IconButtonProps,
  Popover,
  Radio,
  RadioGroup,
  useTheme,
} from '@mui/material';
import { CustomFormControlLabel } from '../../shared/ui/CustomFormControlLabel';
import { BaseBtn, Icon, Row, SearchInput, Text } from '../../shared/ui';
import { PlayArrow } from '@mui/icons-material';
import { useAnchorEl, useExtendedTranslation } from '../../shared/hooks';
import { clear_filter, home } from 'shared/assets';
import { SxProps } from '@mui/system';
interface ResetButtonProps extends IconButtonProps {
  showHomeButton?: boolean;
}

const ResetButton: FC<ResetButtonProps> = ({ onClick, sx, showHomeButton }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: 'absolute',
      zIndex: 2,
      height: '29px',
      width: '33px',
      ...sx,
    }}
  >
    <Icon src={showHomeButton ? home : clear_filter} />
  </IconButton>
);

interface Props {
  title?: string;
  items: { value: string; label: string }[];
  value?: string;
  onChange?: (value: string) => void;
  withSearch?: boolean;
  withConfirm?: boolean;
  hideAllButton?: boolean;
  showHomeButton?: boolean;
  defaultValue?: string;
  sx?: SxProps;
  sxBtn?: SxProps;
  showOnlyResetButton?: boolean;
  noSelectLabel?: string;
  formInput?: boolean;
  disabled?: boolean;
  hideValue?: boolean;
  hideResetButton?: boolean;
  controlsWidth?: number;
  withoutMargin?: boolean;
  big?: boolean;
  translate?: boolean;
}

export const BaseSelect: FC<Props> = ({
  sx,
  sxBtn,
  title,
  formInput,
  items,
  value,
  onChange,
  withSearch,
  hideAllButton,
  showHomeButton,
  defaultValue,
  showOnlyResetButton,
  noSelectLabel,
  disabled,
  hideValue,
  hideResetButton,
  controlsWidth,
  withoutMargin,
  big,
  translate,
}) => {
  const { t } = useExtendedTranslation();
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const [innerValue, setInnerValue] = useState<string>(null);
  const [valueItems, setValueItems] = useState<{ value: string; label: string }[]>(items);
  const [filterItems, setfilterItems] = useState<{ value: string; label: string }[]>(items);
  const handleChange = (value: string) => {
    onChange?.(value);
    onCloseMenu();
  };
  const { palette } = useTheme();

  useEffect(() => {
    setValueItems([...items]);
  }, [items]);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const currentFilter = useMemo(() => valueItems.find((item) => item.value == value), [value, valueItems]);
  const [search, setSearch] = useState('');
  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  useEffect(() => {
    setfilterItems(
      valueItems.filter((i) => {
        const findByLabel = i.label.toLowerCase().includes(search.toLowerCase().trim());
        const findByValue = i.value.toLowerCase().includes(search.toLowerCase().trim());
        return findByLabel || findByValue;
      })
    );
  }, [search, valueItems]);

  const handleApply = () => {
    if (value) {
      onChange?.(innerValue);
    }

    onCloseMenu();
    setSearch('');
  };

  const handleClose = () => {
    onCloseMenu();
  };

  const clearFilterValue = () => onChange?.('');

  const homeButtonClick = () => {
    if (defaultValue) {
      handleChange(defaultValue);
    }
  };
  const isShowResetButton = () => {
    if (hideResetButton) return;
    return value && !isNaN(parseInt(value)) && (value === '87' || showOnlyResetButton);
  };
  const isShowHomeButton = () => {
    return value && !isNaN(parseInt(value)) && value !== '87' && !showOnlyResetButton;
  };
  return (
    <Box sx={{ mr: withoutMargin ? '' : '20px', ...sx }}>
      {formInput ? (
        <Text
          noWrap
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            textTransform: 'capitalize',
            color: palette.primary.dark,
            marginBottom: '3px',
            marginTop: '3px',
          }}
        >
          {title}
        </Text>
      ) : (
        <Text noWrap sx={{ color: 'info.dark', fontSize: '12px', fontWeight: 500, pr: '2px' }}>
          {title}
        </Text>
      )}

      <Row
        position="relative"
        sx={{
          justifyContent: value !== '' && value && !isNaN(parseInt(value)) ? 'space-between' : '',
        }}
      >
        {/* showHomeButton */}
        {showHomeButton && <>{isShowHomeButton() && <ResetButton showHomeButton onClick={homeButtonClick} />}</>}
        {<>{isShowResetButton() ? <ResetButton onClick={clearFilterValue} /> : null}</>}
        {/* {<>{value && !isNaN(parseInt(value)) ? <ResetButton onClick={clearFilterValue} /> : null}</>} */}
        {/* <ResetButton showHomeButton={!!showHomeButton} onClick={resetFilters} /> */}

        <BaseBtn
          btnColor="info.main"
          disabled={disabled}
          onClick={onOpenMenu}
          sx={{
            borderColor: formInput ? 'primary.dark' : palette.info.dark,
            opacity: '1 !important',
            height: big ? '49px' : '29px',
            minWidth: '100%',
            px: 0,
            bgcolor: disabled ? 'info.contrastText' : 'info.main',
            ...sxBtn,
          }}
        >
          <Text
            noWrap
            sx={{
              color: 'primary.dark',
              fontSize: '14px',
              fontWeight: 500,
              width: 'calc(100% - 40px)',
              pl:
                showHomeButton && !hideResetButton
                  ? value !== '' && items.length && value && !isNaN(parseInt(value))
                    ? '35px'
                    : '10px'
                  : '5px',
              textAlign: 'left',
              fontFamily: 'Noto Sans Hebrew !@important',
            }}
          >
            {currentFilter?.value ? (
              <>
                {!hideValue && (
                  <>
                    <span> {currentFilter?.value}</span>
                    {` - `}
                  </>
                )}
                <span>{translate ? t(currentFilter?.label) : currentFilter?.label}</span>
              </>
            ) : (
              <Box component="span" sx={{ color: '#d3d3d3 !important' }}>
                {noSelectLabel ? noSelectLabel : t('display_all_filter_base_select')}
              </Box>
            )}
          </Text>

          <PlayArrow
            sx={{
              m: '7px',
              fontSize: '14px',
              color: formInput ? palette.info.dark : 'primary.dark',
              transform: `rotate(${isOpen ? '-90deg' : '90deg'})`,
            }}
          />
        </BaseBtn>
      </Row>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{
          mt: '5px',
          '& .MuiPaper-root': {
            borderRadius: '12px',
          },
        }}
      >
        <Box>
          {withSearch && (
            <SearchInput
              onfocus
              value={search}
              onChange={handleChangeSearch}
              placeholder={`${t('filter_search_placeholder')}`}
              noBorder
            />
          )}

          <Box
            sx={{
              borderTop: '1px solid grey',
              borderColor: withSearch ? 'info.dark' : 'info.main',
            }}
          >
            {filterItems.length === 0 ? (
              <Text sx={{ color: 'primary.main', textAlign: 'center', my: '10px' }}>{t('filter_no_items')}</Text>
            ) : (
              <FormControl sx={{ width: controlsWidth ? `${controlsWidth}px` : '100%' }}>
                <RadioGroup value={value} sx={{ width: '100%' }}>
                  {!hideAllButton && (
                    <Box
                      sx={{
                        borderBottom: '1px solid black',
                        borderColor: 'primary.light',
                        px: '8px',
                        mb: '2px',
                      }}
                    >
                      <Box
                        onClick={() => handleChange('')}
                        sx={{
                          borderRadius: '10px',
                          cursor: 'pointer',
                          '&:hover': { bgcolor: 'primary.light' },
                        }}
                      >
                        <FormControlLabel
                          control={<Radio checked={value === ''} size="small" />}
                          label={
                            <Text sx={{ color: 'black', pr: '10px' }}>
                              {noSelectLabel ? noSelectLabel : t('display_all_filter_base_select')}
                            </Text>
                          }
                          sx={{
                            fontSize: '11px',
                            mx: 0,
                          }}
                        />
                      </Box>
                    </Box>
                  )}

                  <>
                    <Box
                      sx={{
                        maxHeight: '500px',
                      }}
                    >
                      {defaultValue && (
                        <CustomFormControlLabel
                          sx={{
                            color: 'primary.dark',
                            fontWeight: '700',
                          }}
                          key={value}
                          onChange={() => handleChange(defaultValue)}
                          value={defaultValue}
                          selected
                          labels={{
                            value: defaultValue,
                            label: items.find((item) => item.value === defaultValue)?.label || '',
                          }}
                        />
                      )}
                      {filterItems.map(({ value, label }) => (
                        <CustomFormControlLabel
                          key={value}
                          hideValue={hideValue}
                          onChange={() => handleChange(value)}
                          value={value}
                          labels={{ value, label }}
                          translate={translate}
                        />
                      ))}
                    </Box>
                  </>
                </RadioGroup>
              </FormControl>
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
