import {
  Box,
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import { ChangeEvent, forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { arrow_down_blue, arrow_up_blue } from 'shared/assets';
import { autoFillColor } from 'shared/config';
import { isHebrewFont } from 'shared/lib';
import { Icon } from '../Icon';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Row } from '../Row';
import { search } from '../SearchInput/assets';
import React from 'react';
type BaseInputProps = {
  label?: string;
  big?: boolean;
  large?: boolean;
  medium?: boolean;
  small?: boolean;
  compact?: boolean;
  fullWidth?: boolean;
  smallLabel?: boolean;
  marginRight?: boolean;
  blueBorder?: boolean;
  marginBottom?: number;
  labelMarginBottom?: string;
  maxWidth?: number;
  name?: string;
  changeColorOnFocus?: boolean;
  textColor?: string;
  blur?: (value: string) => void;
  focus?: () => void;
  view?: boolean;
  isTextDirectionRtl?: boolean;
  valueForNumberChanging?: number;
  helperText: string;
  value: string | number;
  disabled?: boolean;
  error?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  sx?: SxProps;
  placeholder?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
};

export const BaseInput = forwardRef<unknown, BaseInputProps>(
  (
    {
      label,
      big,
      medium,
      large,
      small,
      compact,
      error,
      helperText,
      fullWidth,
      smallLabel,
      blueBorder,
      maxWidth,
      marginRight,
      marginBottom,
      labelMarginBottom,
      changeColorOnFocus,
      textColor,
      view,
      blur,
      focus,
      isTextDirectionRtl,
      valueForNumberChanging,
      startAdornment,
      endAdornment,
      ...props
    },
    ref
  ) => {
    const { palette, direction } = useTheme();
    const isHebrew = isHebrewFont(props?.value?.toString() ?? '');
    const isRtl = direction === 'rtl';
    const { t } = useTranslation();
    const [focused, setFocused] = useState(false);

    const handleFocus = () => {
      if (changeColorOnFocus) {
        setFocused(true);
      }
      if (focus) {
        focus();
      }
    };

    const currentRef = useRef(null);

    useEffect(() => {
      if (currentRef.current) {
        currentRef.current.dir = 'auto';
      }
    }, [currentRef]);

    const handleBlur = (value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (changeColorOnFocus) {
        setFocused(false);
      }
      if (blur) {
        blur(value.target.value);
      }
    };
    const handleManualChange = (newValue: number) => {
      const event = {
        target: { value: newValue.toString() },
      } as React.ChangeEvent<HTMLInputElement>;

      props.onChange(event);
    };

    const onButtonClick1 = () => {
      const term = valueForNumberChanging ? valueForNumberChanging : 1;
      const newValue = Math.round((Number(props.value) + term) * 10) / 10;
      handleManualChange(newValue);
    };
    const onButtonClick2 = () => {
      const term = valueForNumberChanging ? valueForNumberChanging : 1;
      const newValue = Math.round((Number(props.value) - term) * 10) / 10;
      handleManualChange(newValue);
    };
    return (
      <Box
        mt={compact ? '3px' : '20px'}
        sx={{
          maxWidth: maxWidth ? `${maxWidth}px` : 'auto',
          width: fullWidth ? '100%' : 'auto',
          mr: marginRight ? '20px' : '0px',
          mb: marginBottom ? `${marginBottom}px` : '0px',
        }}
      >
        {label && (
          <Typography
            noWrap
            sx={{
              fontSize: smallLabel ? '12px' : '14px',
              fontWeight: smallLabel ? 500 : 600,
              textTransform: 'capitalize',
              color: smallLabel ? (focused ? palette.primary.dark : palette.info.dark) : palette.primary.dark,
              marginBottom: labelMarginBottom ? labelMarginBottom : '3px',
            }}
          >
            {label}
          </Typography>
        )}
        <TextField
          fullWidth
          multiline={big || medium || large ? true : false}
          rows={large ? 8 : big ? 5 : medium ? 2 : 1}
          autoComplete="none"
          error={error}
          onFocus={handleFocus}
          onBlur={handleBlur}
          dir={isTextDirectionRtl ? 'rtl' : 'auto'}
          helperText={helperText !== '' && t(`${helperText}_validation_text`)}
          sx={{
            background: palette.info.main,
            borderRadius: '12px',
            color: textColor ? textColor : 'black',
            dir: isTextDirectionRtl ? 'rtl' : 'auto',
            '& .MuiOutlinedInput-root': {
              '& > fieldset.MuiOutlinedInput-notchedOutline': { border: `1px solid ${palette.primary.dark}` },
              '&.Mui-disabled > fieldset': { border: `1px solid ${palette.primary.dark}` },
              '&.Mui-disabled': { bgcolor: 'info.contrastText' },
            },
            '& .Mui-disabled': {
              WebkitTextFillColor: 'info.dark',
            },
            '& .MuiFormHelperText-root': {
              lineHeight: '12px',
              position: 'absolute',
              bottom: '-15px',
              color: 'error.main',
              fontSize: '12px',
              fontWeight: 500,
              fontFamily: 'Noto Sans Hebrew',
              ml: '5px',
            },
            'input, textarea': {
              // textAlign: isHebrewFont(props?.value?.toString() ?? '') ? 'right' : 'left',
              direction: isRtl ? (isHebrew ? 'ltr' : 'rtl') : isHebrew ? 'rtl' : 'ltr',
              fontFamily: 'Noto Sans Hebrew',
              ...autoFillColor,
            },
            'input[type=number]': {
              MozAppearance: 'textfield', //  Firefox
              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                WebkitAppearance: 'none', //  Chrome, Safari, Edge
                margin: 0,
              },
            },
            ...props.sx,
          }}
          inputProps={{ min: 0 }}
          InputProps={{
            sx: {
              height: large ? '210px' : big ? '143px' : medium ? '70px' : small ? '29px' : '49px',
              borderRadius: '12px',
              borderColor: palette.error.main,
              color: textColor ? textColor : 'black',
              ...props.sx,
            },

            startAdornment: startAdornment,
            endAdornment: endAdornment,
          }}
          inputRef={view ? currentRef : ref}
          color="primary"
          {...props}
        />
      </Box>
    );
  }
);
