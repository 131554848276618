import { Box } from '@mui/material';
import { BaseExportToExcel, BaseTable, CustomerSelect, PagePreloader } from 'components';
import { t } from 'i18next';
import { BaseBtn, BigTitle, Row, SearchInput, TotalRows } from 'shared/ui';
import { useSearch, useFormatXlsxData } from './hooks';
import { useEffect, useMemo, useState } from 'react';
import { TABLE_TITLES, TRANSLATE_COLUMNS } from './constants';
import { WeatherStation } from './WeatherStationModels';
import { MeteorologicalStationPopup } from './components';
import { Filter } from 'features/Filter';
import { useFilter } from './hooks';
import { useAppSelector } from 'shared/hooks';
import { useWeatherStationsManager } from './hooks/api';

export const WeatherStationsManager = () => {
  const { searchValue, changeSearch } = useSearch();
  const { formatTable } = useFormatXlsxData();
  const { weatherStations } = useAppSelector((st) => st.weatherStation);
  const [customerFilter, setCustomerFilter] = useState(null);
  const { getWeatherStations, isLoadingWeatherStations } = useWeatherStationsManager();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await getWeatherStations();
      setLoaded(true);
    };
    fetchData();
  }, []);

  const { onChangeTypeFilter, typeFilter, typeFilterItems } = useFilter();
  const [selectedMeteorologicalStation, setSelectedMeteorologicalStation] = useState<WeatherStation>(null);
  const getSearchedWeatherStation = (WeatherStations: WeatherStation[], searchValue: string) => {
    const searched = WeatherStations.filter((c) => {
      const values = Object.values(c);
      return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim()));
    });

    return searched;
  };
  const [openControllerEditModal, setOpenControllerEditModal] = useState(false);
  const filteredWeatherStations = useMemo(() => {
    const serchedWeatherStations = getSearchedWeatherStation(weatherStations, searchValue);
    const filteredWeatherStations = serchedWeatherStations
      .filter((c) => (typeFilter ? c.customerType.toString() === typeFilter : true))
      .filter((c) => (customerFilter ? c.customerID == customerFilter : true));
    return filteredWeatherStations;
  }, [searchValue, typeFilter, customerFilter, weatherStations]);

  const BaseExportToExcelComponent = useMemo(
    () => (
      <BaseExportToExcel
        tableData={filteredWeatherStations}
        sheetName="weather stations"
        fileName="weather_stations.xlsx"
        buttonText={t('weather_stations_export_btn')}
        formattedTable={formatTable(filteredWeatherStations)}
        tableTitles={TABLE_TITLES}
        headerItemsPrefix={'_weather_stations_grid_table'}
        dateColumnIndexes={[6]}
      />
    ),
    [filteredWeatherStations]
  );

  const handleOpenWeatherStation = (weatherStation) => {
    setSelectedMeteorologicalStation(weatherStation);
    setOpenControllerEditModal(true);
  };

  const closeControllerEditModal = () => {
    setOpenControllerEditModal(false);
  };

  const onChangeCustomerFilter = (customer: string) => {
    setCustomerFilter(customer);
  };
  const updateMeteorologicalStation = () => {
    getWeatherStations();
  };
  return (
    <>
      <PagePreloader loading={isLoadingWeatherStations} />
      <Row sx={{ mb: '12px' }}>
        <BigTitle sx={{ mr: '25px' }}>{t('weather_stations_manager_title')}</BigTitle>
        <Row sx={{ flexGrow: 1 }} />
      </Row>
      <SearchInput
        value={searchValue}
        onChange={(e) => changeSearch(e.target.value)}
        placeholder={`${t('search_weather_stations_status_management_page')}`}
      />

      <Row sx={{ mt: '6px' }} flexWrap="wrap" rowGap="5px" alignItems="flex-end">
        <CustomerSelect
          title={`${t('customer_label')}`}
          value={customerFilter}
          onChange={onChangeCustomerFilter}
          hideAllButton
          showHomeButton
          showOnlyResetButton
          withSearch
          sx={{ width: '260px' }}
        />

        {typeFilterItems && (
          <Filter
            title={`${t('customer_type_label')}`}
            value={typeFilter}
            onChange={(value) => {
              onChangeTypeFilter(value);
              //	onSelectFilter();
            }}
            items={typeFilterItems}
            sx={{ width: '260px' }}
            messageEmptyFilter="display_all_customer_type"
          />
        )}

        <Row sx={{ flexGrow: 1 }} />
        <Box sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'flex-end' }}>
          <TotalRows count={filteredWeatherStations.length} />

          {BaseExportToExcelComponent}
        </Box>
      </Row>
      <Box>
        <BaseTable
          tableTitles={TABLE_TITLES}
          tableItems={filteredWeatherStations}
          loadedItems={loaded}
          idField={'objectID'}
          linkIndex={[1]}
          cellClick={handleOpenWeatherStation}
          selectedRowID={selectedMeteorologicalStation ? selectedMeteorologicalStation.objectID : null}
          dateColumnIndexes={[6]}
          //	dateTimeColumnIndexes={[0, 3]}
          noItemsText={t('no_weather_stations_grid_table')}
          headerItemsPrefix={'_weather_stations_grid_table'}
          sortModel={[{ field: 'lastEvaporationDate', sort: 'desc' }]}
          translateColumns={TRANSLATE_COLUMNS}
          //  checkboxSelection
          //onRowSelectionModelChange={rowSelectionModelChange}
          // additionalContent={[{ index: 2, content: (props) => <WaMessageStatus {...props} /> }]}
          loading={isLoadingWeatherStations}
          // stringColumnIndexes={[6]}
          defaultScrollWidth={32}
        />
      </Box>
      <MeteorologicalStationPopup
        currentMeteorologicalStation={selectedMeteorologicalStation}
        openMeteorologicalStation={openControllerEditModal}
        onCloseModal={closeControllerEditModal}
        updateMeteorologicalStation={updateMeteorologicalStation}
        // showRegistersButton
      />
    </>
  );
};
