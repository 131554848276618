import { Box } from '@mui/material';
import { BaseBtn, BaseInput, Icon, Row, Text } from '../../../../shared/ui';
import { useAppDispatch, useAppSelector, useExtendedTranslation, useToast } from 'shared/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useISSData } from '../hooks';
import { BaseSelect } from 'features/BaseSelect';
import { useNavigate } from 'react-router-dom';
import { ISSSiteChanges, ISSSiteCreation } from '../ISSModels';
import localStorageService from 'shared/localStorage';
import { ISSAutocomplete, ISSMap, MaterialsAndPricingTable, PhotoEdit } from '../Components';
import { useSearchParams } from 'react-router-dom';
import { Header } from '../Components/Header';
import { MobileButton, MobileInput, MobileSelect } from './../ui';
import { position } from '../assets';
import { QuantityAndPrice } from '../Components/MaterialsAndPricingTable';
import { compareMaterials } from './Helpers';
import { setGlobalData } from 'shared/slices';
import { CURRENT_STATE, PRIORITY } from './Constants/Index';
import { MobileTextarea } from '../ui/MobileTextarea';
export const ISSSiteManagement = () => {
  const { t } = useExtendedTranslation();
  const { getISSProjects, getISSSites, getISSPhotos, getMaterialLists, getSiteMaterialLists, addSite, editSite } =
    useISSData();
  const { ISSProjects, ISSSites, ISSPhoto, siteMaterials } = useAppSelector((st) => st.ISSSlice);
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [siteOldName, setSiteOldName] = useState<string>('');
  const [siteName, setSiteName] = useState<string>('');
  const [currentState, setCurrentState] = useState<string>('');
  const [currentDescription, setDescription] = useState<string>('');
  const [currentRequiredChanges, setCurrentRequiredChanges] = useState<string>('');
  const [currentAdditionalWorks, setCurrentAdditionalWorks] = useState<string>('');
  const [siteId, setSiteId] = useState<string>('');
  const [mapKey, setMapKey] = useState<string>('');
  const [currentPriority, setCurrentPriority] = useState<number>(1);
  const [localSiteMaterials, setLocalSiteMaterials] = useState<QuantityAndPrice[]>([]);
  const [newCoordinates, setNewCoordinates] = useState<{ lat: string; lng: string } | null>(null);
  const [coordinates, setCoordinates] = useState<{ lat: number; lng: number } | null>(null);
  const userName = localStorageService.currentUserName ?? '';
  const { GOOGLE_MAP_KEY } = useAppSelector((st) => st.globalSlice);
  const [searchParams] = useSearchParams();
  const { showSuccess, showError } = useToast();
  const push = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetch(`/appsettings.json?id=${Date.now()}`)
      .then((response) => response.json())
      .then((data) => dispatch(setGlobalData(data)))
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    if (GOOGLE_MAP_KEY) {
      setMapKey(GOOGLE_MAP_KEY);
    }
  }, [GOOGLE_MAP_KEY]);

  const setDefaultParam = async (siteId: string) => {
    const currentSite = ISSSites.find((el) => {
      return el.id === parseInt(siteId);
    });
    if (!currentSite) return;
    setSelectedProject(currentSite.projectID.toString());
    setSiteOldName(currentSite.siteName);
    setSiteName(currentSite.siteName);
    setCurrentState(currentSite.currentState);
    setDescription(currentSite.description);
    setCurrentRequiredChanges(currentSite.requiredChanges);
    setCurrentAdditionalWorks(currentSite.additionalWorks);
    setCurrentPriority(currentSite.priority);
    setCoordinates({ lat: currentSite.latitude, lng: currentSite.longitude });
    setNewCoordinates({ lat: currentSite.latitude.toString(), lng: currentSite.longitude.toString() });
  };

  useEffect(() => {
    getISSProjects();
    getMaterialLists();
    const siteId = searchParams.get('siteId');
    const projectId = searchParams.get('projectId');
    getSiteMaterialLists(siteId);
    setSelectedProject(projectId);
    if (siteId) {
      setSiteId(siteId);
      getISSSites(projectId);
      getISSPhotos(siteId);
      setDefaultParam(siteId);
    }
  }, []);

  useEffect(() => {
    const siteId = searchParams.get('siteId');
    setDefaultParam(siteId);
  }, [ISSSites]);

  const clickBack = () => {
    push('/mobile/iss');
  };
  const onChangeName = (value: string) => {
    setSiteName(value);
  };
  const onChangeCurrentState = (value: string) => {
    setCurrentState(value);
  };
  const onChangeDescription = (value: string) => {
    setDescription(value);
  };
  const onChangeRequiredChanges = (value: string) => {
    setCurrentRequiredChanges(value);
  };
  const onChangeAdditionalWorks = (value: string) => {
    setCurrentAdditionalWorks(value);
  };
  const onChangeLatitude = (value: string) => {
    setNewCoordinates({ lat: value, lng: newCoordinates ? newCoordinates.lng : '0' });
  };

  const onChangeLongitude = (value: string) => {
    // const filteredValue = value.replace(/[^0-9.,-]/g, '');
    // const formattedValue = filteredValue.replace(',', '.');
    // const lng = parseFloat(formattedValue);
    // if (!isNaN(lng)) {
    //   setCoordinates({ lat: coordinates ? coordinates.lat : 0, lng });
    // }
    setNewCoordinates({ lat: newCoordinates ? newCoordinates.lat : '0', lng: value });
  };
  const setValidLatitude = () => {
    const filteredValue = newCoordinates.lat.replace(/[^0-9.,-]/g, '');
    const formattedValue = filteredValue.replace(',', '.');
    const lat = parseFloat(formattedValue);
    if (!isNaN(lat)) {
      setCoordinates({ lat, lng: coordinates ? coordinates.lng : 0 });
      setNewCoordinates({ lat: lat.toString(), lng: newCoordinates ? newCoordinates.lng : '0' });
    }
  };
  const setValidLongitude = () => {
    const filteredValue = newCoordinates.lng.replace(/[^0-9.,-]/g, '');
    const formattedValue = filteredValue.replace(',', '.');
    const lng = parseFloat(formattedValue);
    if (!isNaN(lng)) {
      setCoordinates({ lat: coordinates ? coordinates.lat : 0, lng });
      setNewCoordinates({ lat: newCoordinates ? newCoordinates.lat : '0', lng: lng.toString() });
    }
  };

  const onChangePriority = (value: number) => {
    if (value < 1 || value > 10) return;
    setCurrentPriority(value);
  };
  // const saveSite = () => {
  //   const siteData: ISSSiteCreation = {
  //     projectId: parseInt(selectedProject),
  //     siteName: siteName,
  //     latitude: coordinates.lat,
  //     longitude: coordinates.lng,
  //     currentState: currentState,
  //     description: currentDescription,
  //     priority: currentPriority,
  //     requiredChanges: currentRequiredChanges,
  //     createdBy: userName,
  //     additionalWorks: currentAdditionalWorks,
  //   };
  //   addSite(siteData);
  // };

  const saveSite = async () => {
    const siteData: ISSSiteChanges = {
      siteName: siteName,
      latitude: coordinates.lat,
      longitude: coordinates.lng,
      currentState: currentState,
      description: currentDescription,
      priority: currentPriority,
      requiredChanges: currentRequiredChanges,
      additionalWorks: currentAdditionalWorks,
    };

    // const saveSite = await editSite({ siteID: parseInt(siteId), changes: siteData });
    // if (!saveSite) {
    //   showError(t('site_update_failed_toast_text'));
    //   return;
    // }

    const result = compareMaterials(siteMaterials, localSiteMaterials);

    console.log('changedElements:', result.quantityDifferences);
    console.log('newElements:', result.newItems);

    siteMaterials;

    localSiteMaterials;

    //showSuccess(t('site_successfully_edited'));
  };

  const onChangeCoordinates = (position: { lat: number; lng: number }) => {
    setCoordinates(position);
    setNewCoordinates({ lat: position.lat.toString(), lng: position.lng.toString() });
  };

  const getCoordinates = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setCoordinates({ lat: latitude, lng: longitude });
          setNewCoordinates({ lat: latitude.toString(), lng: longitude.toString() });
        },
        (error) => {
          console.error('Error getting location:', error.message);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      console.error('Geolocation API notSupport.');
    }
  };
  const onChangeSiteMaterials = (data: QuantityAndPrice[]) => {
    setLocalSiteMaterials(data);
  };

  const photoList = useMemo(
    () =>
      ISSPhoto.map((s) => {
        return {
          data_url: `data:image/jpeg;base64,${s.photoData}`,
        };
      }),
    [ISSPhoto]
  );
  return (
    <Box>
      <Header title={siteOldName ? siteOldName : t('mew_site_edit_site')} />
      <Box sx={{ padding: '20px' }}>
        <MobileInput
          label={`${t('site_name_edit_site')} *`}
          name="siteName"
          error={false}
          helperText={''}
          onChange={(value) => onChangeName(value.target.value)}
          value={siteName}
        />
        <Row spacing={'20px'}>
          <MobileSelect
            title={t('priority_edit_site')}
            items={PRIORITY}
            noSelectLabel={t('priority_placeholder_edit_site')}
            onChange={(value) => onChangePriority(parseInt(value))}
            value={currentPriority.toString()}
          />
          <MobileSelect
            title={t('current_state_edit_site')}
            items={CURRENT_STATE}
            noSelectLabel={t('current_state_placeholder_edit_site')}
            onChange={(value) => onChangeCurrentState(value)}
            value={currentState}
            translate
          />
        </Row>

        {/* <MobileButton onClick={getCoordinates}>
          <Text>{`${t('get_location_button_edit_site')}`}</Text>
          <Icon sx={{ mx: '8px' }} src={position} />
        </MobileButton> */}
        {mapKey && (
          <ISSAutocomplete
            getCoordinates={getCoordinates}
            mapKey={mapKey}
            value={coordinates}
            onChangeCoordinates={onChangeCoordinates}
          />
        )}
        {mapKey && coordinates && (
          <ISSMap mapKey={mapKey} value={coordinates} onChangeCoordinates={onChangeCoordinates} />
        )}
        {newCoordinates && (
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box width="50%">
              <MobileInput
                label={`${t('latitude_edit_site')}`}
                name="latitude"
                error={false}
                helperText={''}
                onChange={(value) => onChangeLatitude(value.target.value)}
                blur={setValidLatitude}
                value={newCoordinates.lat}
              />
            </Box>
            <Row sx={{ width: '20px' }} />
            <Box width="50%">
              <MobileInput
                label={`${t('longitude_edit_site')}`}
                name="longitude"
                error={false}
                helperText={''}
                onChange={(value) => onChangeLongitude(value.target.value)}
                blur={setValidLongitude}
                value={newCoordinates.lng}
              />
            </Box>
          </Box>
        )}

        <MobileInput
          label={`${t('required_changes_edit_site')}`}
          name="requiredChanges"
          error={false}
          helperText={''}
          onChange={(value) => onChangeRequiredChanges(value.target.value)}
          value={currentRequiredChanges}
        />
        <MobileInput
          label={`${t('additional_works_edit_site')}`}
          name="additionalWorks"
          error={false}
          helperText={''}
          onChange={(value) => onChangeAdditionalWorks(value.target.value)}
          value={currentAdditionalWorks}
        />
        <MobileTextarea
          label={`${t('description_edit_site')}`}
          name="description"
          error={false}
          helperText={''}
          onChange={(value) => onChangeDescription(value.target.value)}
          value={currentDescription}
        />

        <MaterialsAndPricingTable onChangeSiteMaterials={onChangeSiteMaterials} />
        <Row>
          <MobileButton btnColor="info.main">
            <Text sx={{ color: 'primary.dark' }}>{`${t('add_item_button_edit_site')}`}</Text>
          </MobileButton>
          <Box sx={{ width: '40px' }}></Box>
          <MobileButton btnColor="info.main">
            <Text sx={{ color: 'primary.dark' }}>{`${t('add_all_button_edit_site')}`}</Text>
          </MobileButton>
        </Row>

        <PhotoEdit photoList={photoList} siteId={siteId} />

        <Box sx={{ display: 'flex', width: '100%' }}>
          <MobileButton onClick={saveSite}>
            <Text>{`${t('save_site_edit_site')}`}</Text>
          </MobileButton>
        </Box>
      </Box>
    </Box>
  );
};
