import useAxios from 'app/useAxios';
import {
  AssignedCustomer,
  IrrigationController,
  IrrigationControllerDataForUpdate,
  IrrigationFactorData,
  MaxEvaporationRate,
  PlantType,
  RainData,
  WeatherStation,
} from 'features/WeatherStationsManager/WeatherStationModels';
import {
  setWeatherStation,
  setAssignedCustomers,
  setPlantType,
  setEvaporationAndRainData,
  setIrrigationFactorData,
  setIrrigationControllers,
  setGeographicZone,
} from 'features/WeatherStationsManager/WeatherStationsSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useToast } from 'shared/hooks';
import { getFormattedDateAndTime } from 'shared/lib';

interface NewMaxEvaporationRate {
  wE_ObjectID: number;
  customerID: number;
  newValue: number;
}

interface RetroactiveUpdateData {
  wE_ObjectID: number;
  customerID: number;
  dateFrom: string;
  dateTo: string;
}
interface TargetFactorUpdateData {
  objectID: number;
  customerID: number;
  date_: string;
  factor: number;
}
interface PlantTypeUpdateData {
  id: number;
  status: number;
}

interface PlantTypeUpdateData {
  id: number;
  status: number;
}

export const useWeatherStationsManager = () => {
  const dispatch = useAppDispatch();
  const { loading, request } = useAxios();
  const { showError } = useToast();
  const { t } = useTranslation();

  const getAllDatesInRange = (from, to) => {
    const dates = [];
    const currentDate = new Date(from);
    const endDate = new Date(to);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate).toISOString().split("T")[0]); // Только YYYY-MM-DD
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  const fillMissingDates = (data, dateFrom, dateTo) => {

    const adjustedDateTo = new Date(dateTo);
    adjustedDateTo.setDate(adjustedDateTo.getDate() - 1);

    const formattedDateTo = adjustedDateTo.toISOString().split("T")[0];

    const allDates = getAllDatesInRange(dateFrom, formattedDateTo);
    const existingDates = new Set(data.map(item => item.date_.split("T")[0]));

    const missingDates = allDates.filter(date => !existingDates.has(date));

    missingDates.forEach(date => {
      data.push({
        objectID: 18323,
        date_: `${date}T00:00:00`,
        rainValue: 0,
        etValue: null,
      });
    });

    data.sort((a, b) => new Date(a.date_).getTime() - new Date(b.date_).getTime());

    return data;
  }

  return {
    getWeatherStations: () =>
      request<undefined, { data: WeatherStation[]; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/weatherStation`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            dispatch(setWeatherStation(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    getAssignedCustomers: (objectID: number) =>
      request<undefined, { data: AssignedCustomer[]; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/assignedCustomers?objectID=${objectID}`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            dispatch(setAssignedCustomers(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    getPlantTypeData: (objectID: number, customerID: number) =>
      request<undefined, { data: PlantType[]; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/plantType?objectID=${objectID}&customerID=${customerID}`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            dispatch(setPlantType(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    getEvaporationAndRainData: (dateFrom: string, dateTo: string, objectID: number) =>
      request<undefined, { data: RainData[]; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/evaporationAndRainData?dateFrom=${dateFrom}&dateTo=${dateTo}&objectID=${objectID}`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            const updatedData = fillMissingDates(data.data, dateFrom, dateTo);
            dispatch(setEvaporationAndRainData(updatedData));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    getIrrigationFactorData: (dateFrom: string, dateTo: string, objectID: number, customerID: number) =>
      request<undefined, { data: IrrigationFactorData[]; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/irrigationFactorData?dateFrom=${dateFrom}&dateTo=${dateTo}&objectID=${objectID}&customerID=${customerID}`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            dispatch(setIrrigationFactorData(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    getMaxEvaporationRate: (objectID: number, customerID: number) =>
      request<undefined, { data: MaxEvaporationRate[]; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `weatherStation/maxEvaporationRate?objectID=${objectID}&customerID=${customerID}`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            return data?.data;
            // dispatch(setPlantType(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    updateMaxEvaporationRate: (data: NewMaxEvaporationRate) =>
      request<undefined, { succeeded: boolean }>({
        method: 'put',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/maxEvaporationRate?`,
        payload: data,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            return true;
            //dispatch(setIrrigationFactorData(data?.data));
          } else {
            showError(t('update_failed_due_to_an_error'));
          }
        },
      }),

    updateRetroactive: (data: RetroactiveUpdateData) =>
      request<undefined, { succeeded: boolean }>({
        method: 'put',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/retroactive`,
        payload: data,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            return true;
            //dispatch(setIrrigationFactorData(data?.data));
          } else {
            showError(t('update_failed_due_to_an_error'));
          }
        },
      }),

    getIrrigationControllers: (objectID: number, customerID: number) =>
      request<undefined, { data: IrrigationController[]; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/irrigationControllers?weObjectID=${objectID}&customerID=${customerID}`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            dispatch(setIrrigationControllers(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    getGeographicZone: (customerID: number) =>
      request<undefined, { data: IrrigationController[]; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/geographicZone?customerID=${customerID}`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            dispatch(setGeographicZone(data?.data));
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    updateTargetFactor: (data: TargetFactorUpdateData[]) =>
      request<undefined, { succeeded: boolean }>({
        method: 'put',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/targetFactor`,
        payload: { factors: data },
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            return true;
            //dispatch(setIrrigationFactorData(data?.data));
          } else {
            showError(t('update_failed_due_to_an_error'));
          }
        },
      }),

    updatePlantType: (data: PlantTypeUpdateData[], objectID: number, customerID: number) =>
      request<undefined, { succeeded: boolean }>({
        method: 'put',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/plantType`,
        payload: {
          objectID,
          customerID,
          PlantTypes: data,
        },
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            return true;
            //dispatch(setIrrigationFactorData(data?.data));
          } else {
            showError(t('update_failed_due_to_an_error'));
          }
        },
      }),

    updateIrrigationControllers: (data: IrrigationControllerDataForUpdate[]) =>
      request<undefined, { data: { result: boolean } }>({
        method: 'put',
        showSuccessMessageCode: undefined,
        url: `/weatherStation/irrigationControllers`,
        payload: {
          controllers: data,
          date: getFormattedDateAndTime(new Date()),
        },
        onDataReceiveCompleted: (data) => {
          if (data.data.result) {
            return true;
          } else {
            showError(t('update_failed_due_to_an_error'));
            return false;
          }
        },
      }),

    loading,
    isLoadingWeatherStations: loading,
  };
};
