import { ConfigurationManager } from 'features';
import { Layout } from '../../shared/ui';
import { Header } from '../../widgets';

export const ConfigurationPage = () => {
  return (
    <Layout>
      <Header />
      <Layout.Body>
        <ConfigurationManager />
      </Layout.Body>
    </Layout>
  );
};
