import { changeLanguage } from 'i18next';
import React, { FC, PropsWithChildren } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext, ILoginResponse } from 'shared/contexts';
import localStorageService from 'shared/localStorage';
export const AuthWrapper: FC<PropsWithChildren> = ({ children }) => {
  const push = useNavigate();
  const { pathname, search } = useLocation();
  const pathname2 = useLocation();

  const getDataFromLocalStorage = () => {
    const data = localStorageService.loginDefaultPage;
    return data ? JSON.parse(data) : null;
  };
  const getDefaultPageLink = (appName?: string) => {
    if (appName) {
      const { returnUrl } = localStorageService;
      if (returnUrl) {
        return returnUrl;
      }
      return 'mobile/iss';
    }
    switch (getDataFromLocalStorage()) {
      case 'dashboard':
        return '/dashboard';
      case 'shortcuts':
        return '/';
      default:
        return '/';
    }
  };
  const handleSaveToken = (data: ILoginResponse, appName?: string) => {
    const { token } = data;
    localStorageService.userToken = token;
    push(getDefaultPageLink(appName));
  };

  const logout = () => {
    localStorageService.userToken = undefined;
    push('/login');
  };

  // React.useEffect(() => {
  //   if (!token) {
  //     push('/login');
  //   }
  // }, []);

  const setISSLang = () => {
    const currentLang = localStorageService.currentLang;
    // if (!currentLang) {
    //   currentLang = 'he';
    // }

    switch (currentLang) {
      case 'he':
        localStorageService.currentLang = 'he-iss';
        changeLanguage('he-iss');
        break;
      case 'en':
        localStorageService.currentLang = 'en-iss';
        changeLanguage('en-iss');
        break;
      case 'ar-sy':
        localStorageService.currentLang = 'ar-SY-iss';
        changeLanguage('ar-SY-iss');
        break;
    }
  };
  const setLang = () => {
    const currentLang = localStorageService.currentLang;
    switch (currentLang) {
      case 'he-iss':
        localStorageService.currentLang = 'he';
        changeLanguage('he');
        break;
      case 'en-iss':
        localStorageService.currentLang = 'en';
        changeLanguage('en');
        break;
      case 'ar-SY-iss':
        localStorageService.currentLang = 'ar-sy';
        changeLanguage('ar-sy');
        break;
    }
  };
  React.useEffect(() => {
    if (pathname.startsWith('/mobile/iss')) {
      console.log(localStorageService.currentLang);
      setISSLang();
      const url = pathname + search;
      const token = localStorageService.userToken;
      const isLogin = url === '/mobile/iss/login';
      if (!isLogin && url != '/mobile/iss') {
        localStorageService.returnUrl = url;
      }

      if (token && isLogin) push(localStorageService.returnUrl ? localStorageService.returnUrl : '/mobile/iss');
      if (!token && !isLogin) push('/mobile/iss/login');
    } else {
      setLang();
      console.log(localStorageService.currentLang);

      const token = localStorageService.userToken;
      const isLogin = pathname === '/login';
      const isResetPAssword = '/reset-password';
      if (isResetPAssword === pathname) return;
      if (token && isLogin) push('/');
      if (!token && !isLogin) push('/login');
    }
  }, [pathname]);

  return <AuthContext.Provider value={{ handleSaveToken, logout }}>{children}</AuthContext.Provider>;
};
