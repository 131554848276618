import { FC, useState, useRef, useEffect } from 'react';
import { Box, FormControl, Popover, RadioGroup } from '@mui/material';
import { CustomFormControlLabel, Text } from 'shared/ui';
import { Icon } from 'shared/ui';
import { MobileInput, MobileSelect } from 'features/Mobile/ISS/ui';
import { chevron } from 'features/Mobile/ISS/assets';
import { QUANTITY } from '../../constants';

interface EditableCellProps {
  value: number;
  onChange: (newValue: number) => void;
}

export const EditableCell: FC<EditableCellProps> = ({ value, onChange }) => {
  const [currentValue, setCurrentValue] = useState<string>(value.toString());
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null); // Реф для MobileInput

  // useEffect(() => {
  //   if (showEdit && inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, [showEdit]);

  const finishEditQuantity = () => {
    setShowEdit(false);
    const newValue = currentValue.replace(/[^0-9]/g, '');
    if (newValue) {
      onChange(parseInt(newValue));
      setCurrentValue(newValue);
    }
  };

  const selectItem = (value: string) => {
    setShowEdit(false);
    onChange(parseInt(value));
    onChange(parseInt(value));
    setCurrentValue(value);
  };

  return (
    <Box sx={{ background: 'white' }}>
      {showEdit ? (
        <Box>
          <Box
            onClick={() => setShowEdit(false)}
            sx={{
              position: 'fixed',
              top: '0px',
              left: '0px',
              width: '100%',
              height: '100%',
              background: 'rgba(0,0,0,0.2)',
              display: 'block',
              zIndex: '1',
            }}
          ></Box>
          <Box sx={{ zIndex: '2', position: 'absolute', top: '0px', width: '60px', left: '8px' }}>
            <MobileInput
              label={``}
              name="Quantity"
              error={false}
              helperText={''}
              onChange={(value) => setCurrentValue(value.target.value)}
              blur={finishEditQuantity}
              value={currentValue}
              inputRef={inputRef}
              sx={{ background: 'white' }}
            />
            <Box>
              <Box>
                <Box
                  sx={{
                    borderTop: '1px solid grey',
                    borderColor: 'info.main',
                  }}
                >
                  {
                    <FormControl sx={{ width: '100%' }}>
                      <RadioGroup value={value} sx={{ width: '100%' }}>
                        <>
                          <Box
                            sx={{
                              maxHeight: '500px',
                            }}
                          >
                            {QUANTITY.map(({ value, label }) => (
                              <CustomFormControlLabel
                                key={value}
                                onChange={() => selectItem(value)}
                                value={currentValue}
                                labels={{ label }}
                                sx={{ background: 'white', width: '60px', ml: '0' }}
                              />
                            ))}
                          </Box>
                        </>
                      </RadioGroup>
                    </FormControl>
                  }
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <MobileSelect
            title={''}
            items={QUANTITY}
            noSelectLabel={''}
            onChange={(value) => setCurrentValue(value)}
            value={currentValue}
            translate
          /> */}
        </Box>
      ) : (
        <Box
          sx={{
            height: '20px',
            padding: '16px',
            boxShadow: '0px 1px 3px 0px #0000001A,  0px 1px 2px 0px #0000001A',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '60px',
          }}
          onClick={() => setShowEdit(true)}
        >
          <Box sx={{ width: '40px' }}>
            <Text sx={{ color: 'secondary.dark' }}>{value}</Text>
          </Box>
          <Icon src={chevron} />
        </Box>
      )}
    </Box>
  );
};
