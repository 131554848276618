import { Box } from '@mui/material';
import { BaseInput } from 'shared/ui';
import { SxProps } from '@mui/system';
import { FC, MutableRefObject, useState } from 'react';

type Props = {
  label: string;
  name: string;
  error: boolean;
  helperText: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  blur?: (value: string) => void;
  value: string | number;
  sx?: SxProps;
  type?: string;
  valueForNumberChanging?: number;
  inputRef?: MutableRefObject<HTMLInputElement>;
};
export const MobileTextarea: FC<Props> = ({ sx = {}, ...props }) => {
  const [isBigSize, setIsBigSize] = useState<boolean>(false);

  const onBlur = () => {
    setIsBigSize(false);
  };
  const onFocus = () => {
    setIsBigSize(true);
  };

  return (
    <BaseInput
      sx={{
        borderRadius: '4px',
        width: '100%',
        ...sx,
      }}
      blur={onBlur}
      focus={onFocus}
      medium={!isBigSize}
      large={isBigSize}
      {...props}
    />
  );
};
