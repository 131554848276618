interface CustomController {
  id: string | null;
  objectName: string | null;
  customerName: string | null;
  customerId: string | null;
  checkboxes: string | null;
}
export type TableTitle = { title: string; key: keyof CustomController; width: number | string };

export const TABLE_TITLES: TableTitle[] = [
  { title: '', key: 'checkboxes', width: 55 },
  { title: 'controller_id', key: 'id', width: 60 },
  { title: 'controller_name', key: 'objectName', width: 167 },
  { title: 'customer_id', key: 'customerId', width: 60 },
  { title: 'customer_name', key: 'customerName', width: 145 },
];
