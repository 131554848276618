import { t } from 'i18next';
import { BigTitle, Layout } from '../../shared/ui';
import { Header,} from '../../widgets';
import { TimeZoneManager } from 'features';
export const TimeZone = () => {
  return (
		<Layout>
		<Header />
			<Layout.Body>
				<TimeZoneManager />
			</Layout.Body>
		</Layout>
  );
};
