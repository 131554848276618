import { Box } from '@mui/material';
import { BaseExportToExcel, BaseMultiselect, BaseTable, PagePreloader } from 'components';
import { BaseDataAndTimePicker, BaseDataPicker, Filter } from 'features';
import { EditController } from 'features/ControllerManager/components';
import { GROUP_FOR_DEVICE_TYPE_OTHER } from 'features/DashboardManager/Constants';
import { useEffect, useMemo, useState, useTransition } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDashboardDetails, useGetCustomerTypes, useGetCustomers, useModemAndModemBoardTypes } from 'shared/api';
import { useAppDispatch, useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { getSearchedDashboardDetails } from 'shared/lib';
import localStorageService from 'shared/localStorage';
import { Controller, DashboardDetails } from 'shared/models';
import { setCurrentController, toggleEditController } from 'shared/slices';
import { BaseBtn, BigTitle, Row, SearchInput } from 'shared/ui';
import TotalRows from 'shared/ui/TotalRows';
import { FilterBlock } from './components/FilterBlock';
import { TABLE_TITLES, TRANSLATE_COLUMNS } from './constants';
import { useFilterControllers, useFormatXlsxData, useSearchControllers } from './hooks';

export const ControllerDetailsManager = () => {
  const dispatch = useAppDispatch();
  const { getCustomer } = useGetCustomers();
  const { getCustomerTypes } = useGetCustomerTypes();
  const { getModemAndModemBoardTypes } = useModemAndModemBoardTypes();
  const { isLoadingDashboardDetails, getDashboardDetails } = useDashboardDetails();
  const [isPending, startTransition] = useTransition();
  const [preloader, setPreloader] = useState<boolean>(false);
  const loading = isLoadingDashboardDetails;
  const currentLang = localStorageService.currentLang;
  const {
    customersFilter,
    onChangeCustomersFilter,
    customersFilterItems,
    modelFilter,
    onChangeModelFilter,
    modelFilterItems,
    onChangeDeviceTypeFilter,
    deviceTypeFilter,
    deviceTypeFilterItems,
    deviceStatusFilter,
    onChangeDeviceStatusFilter,
    deviceStatusItems,
    typeFilter,
    onChangeTypeFilter,
    typeFilterItems,
    startLastCommDate,
    onChangeStartLastCommDate,
    endLastCommDate,
    onChangeEndLastCommDate,
    startInstallDate,
    onChangeStartInstallDate,
    endInstallDate,
    onChangeEndInstallDate,
    cellularProvider,
    onChangeCellularProvider,
    cellularProviderFilterItems,
    modemTypesItems,
    onChangeModemTypesItems,
    modemTypesFilter,
    modemBoardTypesItems,
    onChangeModemBoardTypesItems,
    modemBoardTypesFilter,
    setQueryParam
  } = useFilterControllers();
  const { customerTypes } = useAppSelector((st) => st.customers);
  const { dashboardDetails } = useAppSelector((st) => st.dashboardDetails);
  const { formatTable } = useFormatXlsxData();
  const [selectedController, setSelectedController] = useState<Controller>(null);
  const [filtersConfigured, setFiltersConfigured] = useState<boolean>(true);
  const [openEditController, setOpenEditController] = useState<boolean>(false);
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([getCustomer(), getCustomerTypes(), getModemAndModemBoardTypes()]);
    };
    fetchData();
  }, []);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    loadDashboardDetails();
  }, [location.search]);

  const { searchValue, changeSearch } = useSearchControllers();
  const { t } = useExtendedTranslation();
  const DATE_COLUMN_INDEXES = [8];

  const filteredDashboardDetails = useMemo(() => {
    const filteredDashboardDetails = getSearchedDashboardDetails(dashboardDetails, searchValue);
    return filteredDashboardDetails;
  }, [searchValue, dashboardDetails]);

  const loadDashboardDetails = () => {
    if (!filtersConfigured) return;
    const customerIds = customersFilter.map((item) => parseInt(item));

    const formatDateTime = (dateAndTime: string) => {
      if (!dateAndTime) return dateAndTime;
      const [datePart, timePart] = dateAndTime.split(' ');
      const dateTime = datePart + 'T' + timePart;
      return dateTime;
    };

    const data = {
      deviceTypes: deviceTypeFilter,
      customerIds: customerIds,
      modelIds: modelFilter,
      customerType: parseInt(typeFilter),
      cellProvider: cellularProvider,
      disabledFlag: null,
      lastCommDates: {
        from: formatDateTime(startLastCommDate),
        to: formatDateTime(endLastCommDate),
      },
      installDates: {
        from: startInstallDate,
        to: endInstallDate,
      },
      modemBoardTypes: modemBoardTypesFilter,
      modemTypes: modemTypesFilter,
    };
    if (deviceTypeFilter && deviceTypeFilter.length && deviceTypeFilter.includes('OTHER')) {
      deviceTypeFilter.push(...GROUP_FOR_DEVICE_TYPE_OTHER);
      data.deviceTypes = deviceTypeFilter;
    }
    if (deviceStatusFilter) {
      data.disabledFlag = deviceStatusFilter === 'active' ? true : false;
    }
    getDashboardDetails(data);
  };
  const onSelectFilter = () => {
    startTransition(() => { });
  };
  const handleOpenControllerEditModal = (row: DashboardDetails) => {
    //setOpenControllerEditModal(true);
    const currentController: Controller = {
      customerId: row.customerID,
      controllerName: row.customerName,
      customerName: row.customerName,
      deviceId: row.objectID,
      hwId: row.hwid,
      modelId: row.modelID,
      objectName: row.objectName,
      stationId: null,
      gatewayId: null,
      driverCode: null,
      objStatus: null,
      isObjectDisabled: null,
      objectNotes: row.notes,
      installAddress: null,
      gisHorisontal: null,
      gisVertical: null,
      latitudeDec: row.latitude_Dec,
      longitudeDec: row.longitude_Dec,
      installDate: row.installDate,
      powerConnectDate: null,
    };
    setSelectedController(currentController);
    dispatch(setCurrentController(currentController));
    dispatch(toggleEditController());
    setOpenEditController(true);
  };
  const closeControllerEditModal = () => {
    setOpenEditController(false);
  };
  const [newStartLastCommDate, changeNewStartLastCommDate] = useState(startLastCommDate);
  const [newEndLastCommDate, changeNewEndLastCommDate] = useState(endLastCommDate);
  const forceRefresh = () => {
    const newParams = location.search.includes("&*") ? location.pathname + location.search.replaceAll('&*', '') : location.pathname + location.search + "&*";
    navigate(newParams);

  };
  const updateControllersDetails = () => {

    onSelectFilter();
    onChangeStartLastCommDate(newStartLastCommDate);
    onChangeEndLastCommDate(newEndLastCommDate);
    forceRefresh()

  };
  const clearNewEndLastCommDate = () => {
    onSelectFilter();
    changeNewEndLastCommDate(null);
    onChangeEndLastCommDate(null);
  };
  const clearNewStartLastCommDate = () => {
    onSelectFilter();
    changeNewStartLastCommDate(null);
    onChangeStartLastCommDate(null);
  };

  const controllerBaseTable = useMemo(
    () => (
      <BaseTable
        tableItems={filteredDashboardDetails}
        loadedItems={true}
        idField={'objectID'}
        noItemsText={t('no_controllers_grid_table')}
        headerItemsPrefix={'_dashboard_details_grid_table'}
        dateColumnIndexes={DATE_COLUMN_INDEXES}
        dateTimeColumnIndexes={[9]}
        selectedRowID={selectedController?.deviceId}
        maxHeight="calc(100vh - 405px)"
        height="calc(100vh - 405px)"
        tableTitles={TABLE_TITLES}
        translateColumns={TRANSLATE_COLUMNS}
        linkIndex={[2]}
        cellClick={handleOpenControllerEditModal}
        loading={loading || isPending}
      />
    ),
    [filteredDashboardDetails, selectedController]
  );

  const BaseExportToExcelComponent = useMemo(
    () => (<BaseExportToExcel
      tableData={filteredDashboardDetails}
      sheetName="dashboardDetails"
      fileName="dashboardDetails.xlsx"
      buttonText={t('export_controllers_page_button')}
      dateColumnIndexes={DATE_COLUMN_INDEXES}
      dateAndTimeColumnIndexes={[9]}
      formattedTable={formatTable(filteredDashboardDetails)}
      tableTitles={TABLE_TITLES}
      headerItemsPrefix={'_dashboard_details_grid_table'}
      needCutHeaderName
    />)
    ,
    [filteredDashboardDetails]
  );

  return (
    <>
      <PagePreloader loading={loading || isPending} />
      {/* <div ref={tableRef} style={{ width: '100%', position: 'relative', height: '210px', opacity: disabled ? 0.5 : 1 }}> */}
      <Row sx={{ mb: '12px' }}>
        <BigTitle sx={{ mr: '25px' }}>{t('controllers_details_title')}</BigTitle>
      </Row>
      <SearchInput
        value={searchValue}
        onChange={changeSearch}
        placeholder={`${t('filter_search_placeholder')}`}
        sx={{ mb: '12px' }}
      />
      <Row sx={{ mb: '12px', alignItems: 'flex-start' }} flexWrap="wrap" rowGap="5px">
        <Box maxWidth={'12%'}>
          <BigTitle sx={{ fontSize: '24px', mr: '20px', mt: '16px' }}>{t('filters_details_title')}</BigTitle>
          <BigTitle
            sx={{ fontSize: '24px', mr: '20px', mt: '90px', lineHeight: '24px', display: 'flex', alignItems: 'end' }}
          >
            <span>{t('install_details_title')} </span>
          </BigTitle>
        </Box>
        <FilterBlock>
          <BaseMultiselect
            title={`${t('customer_label')}`}
            values={customersFilter}
            onChange={onChangeCustomersFilter}
            applyFilter={onSelectFilter}
            items={customersFilterItems}
            withSearch
            sx={{ width: '90%' }}
          />
          <BaseMultiselect
            title={`${t('modem_type_label')}`}
            values={modemTypesFilter}
            onChange={onChangeModemTypesItems}
            applyFilter={onSelectFilter}
            items={modemTypesItems}
            withSearch
            hideValue
            sx={{ width: '90%' }}
          />

          <Box sx={{ width: '90%' }}>
            <BaseDataPicker
              label={`${t('start_date_filter_install_date_label')}`}
              name="startInstallDate"
              value={startInstallDate}
              onChange={(name, value) => {
                onSelectFilter();
                onChangeStartInstallDate(value);
              }}
              clearIcon
            />
          </Box>
        </FilterBlock>
        <FilterBlock>
          {modelFilterItems && (
            <BaseMultiselect
              title={`${t('model_id_label')}`}
              values={modelFilter}
              onChange={onChangeModelFilter}
              applyFilter={onSelectFilter}
              items={modelFilterItems}
              withSearch
              hideValue
              sx={{ width: '90%' }}
            />
          )}
          <BaseMultiselect
            title={`${t('modem_board_type_label')}`}
            values={modemBoardTypesFilter}
            onChange={onChangeModemBoardTypesItems}
            applyFilter={onSelectFilter}
            items={modemBoardTypesItems}
            withSearch
            hideValue
            sx={{ width: '90%' }}
          />
          <Box sx={{ width: '90%' }}>
            <BaseDataPicker
              label={`${t('end_date_filter_install_date_label')}`}
              name="endInstallDate"
              value={endInstallDate}
              onChange={(name, value) => {
                onSelectFilter();
                onChangeEndInstallDate(value);
              }}
              error={null}
              clearIcon
            />
          </Box>
        </FilterBlock>
        <FilterBlock>
          <BaseMultiselect
            title={`${t('device_type_label')}`}
            values={deviceTypeFilter}
            onChange={onChangeDeviceTypeFilter}
            applyFilter={onSelectFilter}
            items={deviceTypeFilterItems}
            withSearch
            hideValue
            sx={{ width: '90%' }}
          />
          <BigTitle
            sx={{
              fontSize: '24px',
              mr: '20px',
              mt: '24px',
              display: 'flex',
              justifyContent: 'flex-end',
              lineHeight: '24px',
              alignItems: 'end',
            }}
          >
            {t('last_details_title')}{' '}
          </BigTitle>
        </FilterBlock>
        <FilterBlock>
          <Filter
            title={`${t('device_status_label')}`}
            value={deviceStatusFilter}
            onChange={(value) => {
              onSelectFilter();
              onChangeDeviceStatusFilter(value);
            }}
            items={deviceStatusItems}
            messageEmptyFilter="disconnect_display_all"
            sx={{ width: '90%' }}
            placeholder={`${t('filter_service_calls_battery_status_page_placeholder')}`}
            hideResetButton
          />
          <Box sx={{ width: '90%' }}>
            <BaseDataAndTimePicker
              label={`${t('start_date_filter_last_comm_label')}`}
              name="startLastCommDate"
              value={newStartLastCommDate}
              onChange={(name, value) => {
                changeNewStartLastCommDate(value);
              }}
              changedText={(value) => {
                changeNewStartLastCommDate(value);
              }}
              clearFilterValue={clearNewStartLastCommDate}
              clearIcon
            />
          </Box>
        </FilterBlock>
        <FilterBlock>
          {customerTypes && customerTypes.length ? (
            <Filter
              title={`${t('customer_type_label')}`}
              value={typeFilter}
              onChange={(value) => {
                onChangeTypeFilter(value);
                onSelectFilter();
              }}
              items={typeFilterItems}
              sx={{ width: '90%' }}
              messageEmptyFilter="display_all_customer_type"
            />
          ) : (
            <></>
          )}
          <Box sx={{ width: '90%' }}>
            <BaseDataAndTimePicker
              label={`${t('end_date_filter_last_comm_label')}`}
              name="endLastCommDate"
              value={newEndLastCommDate}
              onChange={(name, value) => {
                changeNewEndLastCommDate(value);
              }}
              changedText={(value) => {
                changeNewEndLastCommDate(value);
              }}
              error={null}
              clearFilterValue={clearNewEndLastCommDate}
              clearIcon
            />
          </Box>
        </FilterBlock>

        <FilterBlock>
          <Filter
            title={`${t('cellular_provider_label')}`}
            value={cellularProvider}
            onChange={(value) => {
              onChangeCellularProvider(value);
              onSelectFilter();
            }}
            items={cellularProviderFilterItems}
            sx={{ width: '90%' }}
            hideResetButton
            messageEmptyFilter="display_all_cellular_provider"
          />
          <BaseBtn
            onClick={(e) => {
              updateControllersDetails();
            }}
            sx={{ height: '29px' }}
          >
            {`${t('update_controllers_details')}`}
          </BaseBtn>
          {/* <Row sx={{ height: '50px' }} /> */}
        </FilterBlock>
        <Row sx={{ flexGrow: 1 }} />
        <Box width={'15%'} sx={{ display: 'flex', flexDirection: 'column' }} rowGap="15px">
          <Box sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'flex-end' }}>
            <TotalRows count={loading ? 0 : dashboardDetails.length} />
            {!isPending && BaseExportToExcelComponent}
          </Box>
          <Row sx={{ height: '50px' }} />
        </Box>
      </Row>
      <Row sx={{ mb: '12px' }} flexWrap="wrap" rowGap="5px"></Row>
      {!isPending && (
        controllerBaseTable
      )}
      <EditController
        currentController={selectedController}
        openControllerEditModal={openEditController}
        onCloseModal={closeControllerEditModal}
        showRegistersButton
      />
    </>
  );
};
