import useAxios from 'app/useAxios';
import { AddTimeZone, TimeZone, TimeZoneRealData, UpdateTimeZone } from 'features/TimeZoneManager/timeZoneModels';
import { setTimeZones } from 'features/TimeZoneManager/timeZoneSlice';
import { t } from 'i18next';
import { useAppDispatch, useToast } from 'shared/hooks';
export const enum DataSourceTypes {Realtime=0,History=1}
export const useTimeZone = () => {
  const dispatch = useAppDispatch();
  const { loading, request,error} = useAxios();
  const {  showSuccess } = useToast();

  return {
    getTimeZone: () =>
      request<undefined, { data: TimeZone[]; succeeded: boolean }>({
        method: 'get',
        url: `/timeZone`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
           dispatch(setTimeZones(data?.data));
        },
      }),
      syncTimeZone: (zone:string) =>
      request<undefined, { data: TimeZoneRealData; succeeded: boolean }>({
        method: 'get',
        url: `/timeZone/realTimeData?zone=${zone}`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          return data.data
        },
      }),

      updateTimeZone: (data:UpdateTimeZone) =>
      request<undefined, { data: TimeZone[]; succeeded: boolean }>({
        method: 'put',
        url: `/timeZone`,
        showSuccessMessageCode: undefined,
        payload: data,
        onDataReceiveCompleted: (data) => {
          showSuccess(t('time_zone_successfully_updated_toast_text'));
        },
      }),
      addTimeZone: (data:AddTimeZone) =>
        request<undefined, { data: TimeZone[]; succeeded: boolean }>({
          method: 'post',
          url: `/timeZone`,
          showSuccessMessageCode: undefined,
          payload: data,
          onDataReceiveCompleted: (data) => {
            showSuccess(t('time_zone_successfully_added_toast_text'));
          },
        }),

      syncTimeZones: () =>
      request<undefined, { data: TimeZoneRealData; succeeded: boolean }>({
        method: 'post',
        url: `/timezone/updateTransitions`,
        showSuccessMessageCode: undefined,
        payload: {},
        onDataReceiveCompleted: (data) => {
          return data.data
        },
      }),

      // syncTimeZoneStatus: () =>
      // request<undefined, { data: any }>({
      //   method: 'get',
      //   url: `/timezone/updateTransitionsStatus`,
      //   showSuccessMessageCode: undefined,
      //   onDataReceiveCompleted: (data) => {
      //     return data
      //   },
      // }),

      syncTimeZoneStatus: () =>
      request<undefined, { data: {isProcessing:boolean}; succeeded: boolean }>({
        method: 'get',
        url: `/timezone/updateTransitionsStatus`,
        showSuccessMessageCode: undefined,
        onDataReceiveCompleted: (data) => {
          // dispatch(setTimeZones(data?.data));
        },
      }),


      isLoading: loading,
      error



      
  };
};