import { BaseBtn, Icon, Row, Text } from 'shared/ui';
import { SxProps } from '@mui/system';
import { FC, useEffect, useMemo, useState } from 'react';
import { Box, ButtonProps, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useAppSelector } from 'shared/hooks';
import { t } from 'i18next';
import { MobileInput } from '../../ui';
import { chevron } from '../../assets';
import { EditableCell } from './components';

interface Props {
  loading?: boolean;
  sx?: SxProps;
  onChangeSiteMaterials: (newMaterialLists: QuantityAndPrice[]) => void;
}
export interface QuantityAndPrice {
  quantity: number;
  id: number;
  unitCost: number;
  materialCode: string;
  shortDescription: string;
  unitType: string;
}

export const MaterialsAndPricingTable: FC<Props> = ({ sx = {}, onChangeSiteMaterials, ...props }) => {
  const { siteMaterials, materialLists } = useAppSelector((st) => st.ISSSlice);
  const [quantityAndPrice, setQuantityAndPrice] = useState<QuantityAndPrice[]>([]);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');
  useEffect(() => {
    if (materialLists.length && siteMaterials.length) {
      const siteMaterialsItems = siteMaterials.map((s) => {
        const currentMaterial = materialLists.find((item) => item.id == s.materialID);

        return {
          quantity: s.quantity,
          materialCode: currentMaterial.materialCode,
          id: currentMaterial.id,
          unitType: currentMaterial.unitType,
          shortDescription: currentMaterial.shortDescription,
          unitCost: currentMaterial.unitCost,
        };
      });
      setQuantityAndPrice(siteMaterialsItems);
    }
  }, [siteMaterials, materialLists]);

  useEffect(() => {
    const newTotalCost = quantityAndPrice.reduce((sum, item) => sum + item.quantity * item.unitCost, 0);
    setTotalCost(newTotalCost);
    onChangeSiteMaterials(quantityAndPrice);
  }, [quantityAndPrice]);

  const onChangeQuantity = (value: number, id: number) => {
    setQuantityAndPrice((prev) => prev.map((item) => (item.id === id ? { ...item, quantity: value } : item)));
  };

  const onChangeSearch = (value: string) => {
    setSearchText(value);
  };

  //quantityAndPrice

  const searchQuantityAndPrice = (filteredItems: QuantityAndPrice[], searchText: string) => {
    const searched = filteredItems.filter((c) => {
      const values = Object.values(c);

      return values.some((v) => v?.toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase().trim()));
    });

    return searched;
  };

  const filteredQuantityAndPrice: QuantityAndPrice[] = useMemo(() => {
    const filteredItems = quantityAndPrice;
    const searchedItems = searchQuantityAndPrice(filteredItems, searchText);

    return searchedItems;
  }, [searchText, quantityAndPrice]);

  return (
    <Box>
      <MobileInput
        label={`${t('materials_and_pricing_table')}`}
        name="requiredChanges"
        error={false}
        helperText={''}
        onChange={(value) => onChangeSearch(value.target.value)}
        value={searchText}
        search
      />
      <Table>
        <TableHead>
          <TableRow sx={{ background: '#FAFAFA' }}>
            <TableCell sx={{ height: '60px', padding: '8px' }}>
              <Text sx={{ color: 'secondary.dark' }}>{t('code_material_table')}</Text>
            </TableCell>
            <TableCell sx={{ height: '60px', padding: '8px' }}>
              <Text sx={{ color: 'secondary.dark' }}> {t('description_material_table')}</Text>
            </TableCell>
            <TableCell sx={{ height: '60px', padding: '8px' }}>
              <Text sx={{ color: 'secondary.dark' }}> {t('unit_material_table')}</Text>
            </TableCell>
            <TableCell sx={{ height: '60px', padding: '8px' }}>
              <Text sx={{ color: 'secondary.dark' }}> {t('qty_material_table')}</Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredQuantityAndPrice.map((item, index) => (
            <TableRow key={`key-${item.id}`} sx={{ background: index % 2 ? '#FAFAFA' : 'white' }}>
              <TableCell sx={{ height: '60px', padding: '8px' }}>
                <Text sx={{ color: 'secondary.dark' }}>{item.materialCode}</Text>
              </TableCell>
              <TableCell sx={{ height: '60px', padding: '8px' }}>
                <Text sx={{ color: 'secondary.dark' }}>{item.shortDescription}</Text>
              </TableCell>
              <TableCell sx={{ height: '60px', padding: '8px' }}>
                <Text sx={{ color: 'secondary.dark' }}>{item.unitType}</Text>
              </TableCell>
              <TableCell sx={{ height: '60px', padding: '8px', position: 'relative' }}>
                <EditableCell value={item.quantity} onChange={(value) => onChangeQuantity(value, item.id)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* <Box
        sx={{
          height: '60px',
          padding: '8px',
          boxShadow: '0px 1px 3px 0px #0000001A,  0px 1px 2px 0px #0000001A',
          borderRadius: '8px',
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text
          sx={{
            color: 'secondary.dark',
            fontSize: '1rem',
          }}
        >
          {t('total_cost_edit_site')}
        </Text>
        <Row>
          <Text
            sx={{
              color: 'secondary.dark',
              fontSize: '1rem',
            }}
          >
            ₪
          </Text>
          <Text
            sx={{
              color: 'secondary.dark',
              fontSize: '1rem',
              mr: '20px',
            }}
          >
            {totalCost}
          </Text>
        </Row>
      </Box> */}
    </Box>
  );
};
