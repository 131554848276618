import { Box, IconButton, Stack } from '@mui/material';
import { AssignedCustomer } from 'features/WeatherStationsManager/WeatherStationModels';
import { t } from 'i18next';
import { FC } from 'react';
import { close_popup } from 'shared/assets';
import { BaseBtn, BigTitle, Modal, Row } from 'shared/ui';


interface Props {
	openModal: boolean;
	onCloseModal: () => void;
	onConfirm: () => void;
}

export const IsSyncConfirmModal: FC<Props> = ({
	openModal,
	onCloseModal,
	onConfirm
}) => {

	return (
		<Modal open={openModal} sx={{ margin: 'auto', width: '680px' }} onClose={onCloseModal}
			padding={'8px 16px'}>
			<Box>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<BigTitle>{t('is_sync_confirm_modal_title')} </BigTitle>
					<IconButton onClick={onCloseModal}>
						<img src={close_popup} />
					</IconButton>
				</Stack>
				<Box sx={{ m: '8px' }}>{t('are_you_sure_is_sync_confirm_modal')}
				</Box>
				<Row justifyContent="center">
					<Row width={300} gap="10px">
						<BaseBtn onClick={onCloseModal} btnColor="info.main" fullWidth>
							{t('cancel_confirm_modal_button')}
						</BaseBtn>
						<BaseBtn onClick={onConfirm} fullWidth>
							{t('confirm_confirm_modal_button')}
						</BaseBtn>
					</Row>
				</Row>
			</Box>
		</Modal >
	);
};
