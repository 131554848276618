import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'shared/hooks';
import { useNavigate } from 'react-router-dom';
import { useISSData } from '../../hooks';
import { BaseBtn, Row, Text } from '../../../../../shared/ui';
import { t } from 'i18next';
import { BaseSelect } from 'features/BaseSelect';

export const AddSiteMaterial = () => {
  const { getISSProjects, getMaterialLists } = useISSData();
  const { materialLists } = useAppSelector((st) => st.ISSSlice);
  const [selectedMaterial, setSelectedMaterial] = useState<string>('');
  useEffect(() => {
    getISSProjects();
    getMaterialLists();
  }, []);

  const materialItems = useMemo(
    () =>
      materialLists.map((s) => ({
        value: s.id.toString(),
        label: `${s.materialCode} ${s.shortDescription}`,
      })),
    [materialLists]
  );

  const onChangeMaterial = (id: string) => {
    setSelectedMaterial(id);
  };

  return (
    <Box sx={{ p: { xs: '18px 31px', xl: '58px 61px' } }}>
      <Text
        sx={{
          color: 'primary.main',
          fontFamily: 'Noto Sans Hebrew',
          mb: { xs: '15px', xl: '50px' },
          fontSize: { xs: '41px', xl: '64px' },
          lineHeight: { xs: '46px', xl: '96px' },
          textAlign: 'center',
        }}
      >
        {t('add_material_to_site_header_text')}
      </Text>
      <Box>
        <BaseSelect
          value={selectedMaterial}
          onChange={onChangeMaterial}
          items={materialItems}
          hideAllButton
          hideValue
          controlsWidth={200}
          noSelectLabel={`${t('select_material_iss_page')}`}
          sx={{ width: '200px', mb: '16px' }}
        />
      </Box>
    </Box>
  );
};
