import { Box, IconButton, Stack, Typography } from '@mui/material';
import { BaseDataPicker, ServiceCalls } from 'features';
import GoogleMapReact from 'google-map-react';
import { t } from 'i18next';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { useDashboardDetails, useEditController, useGetCustomerAgreementCodes } from 'shared/api';
import { useControllerRegisters } from 'shared/api/useControllerRegister';
import { close_popup, registers } from 'shared/assets';
import { useAnchorEl, useAppDispatch, useAppSelector, useControllerDateErrors } from 'shared/hooks';
import { replaceSpacesWithUnderscores } from 'shared/lib/replaceSpacesWithUnderscores';
import { Controller, ControllerRegisters } from 'shared/models';
import { clearDailyConsumption, toggleEditController } from 'shared/slices';
import { BaseBtn, BaseCollapse, BaseInput, BaseTabs, BigTitle, Confirm, Icon, Modal, Row } from 'shared/ui';
import { EditRealTimeControllerRegisters, MapModal } from 'widgets';
import {
  BatteryStatusInfo,
  ControllerEventHistory,
  ControllerHeader,
  ControllerSettingsModal,
  EditControllerClearDataModal,
  LastIrrigationData,
} from './components';
import { useTabs } from './hooks';
import { BaseTimezoneSelect } from 'components';
import { useControllerTimezone } from 'features/ControllerManager/hooks';
const initialValues = {
  customerId: 0,
  controllerName: '',
  deviceId: 0,
  hwId: 0,
  modelId: null,
  objectName: '',
  stationId: 0,
  gatewayId: 0,
  driverCode: '',
  objStatus: 0,
  isObjectDisabled: false,
  objectNotes: '',
  installAddress: '',
  gisHorisontal: '',
  gisVertical: '',
  latitudeDec: 0,
  longitudeDec: 0,
  installDate: '',
  powerConnectDate: '',
  powerSupCode: null,
  deviceType: '',
  customerAgreementStatus: null,
};

const initialErrors = {
  customerName: '',
  objectName: '',
  installAddress: '',
  objectNotes: '',
  latitudeDec: '',
  longitudeDec: '',
};

interface Props {
  showRegistersButton?: boolean;
  currentController: Controller;
  openControllerEditModal: boolean;
  onCloseModal: () => void;
  reportEndDate?: string;
  reportStartDate?: string;
  faultEquipmentCode?: number;
  updateReport?: () => void;
  defaultTab?: number;
  defaultCollapse?: string[];
}

const Edit: FC<Props> = ({
  showRegistersButton,
  currentController,
  openControllerEditModal,
  onCloseModal,
  reportEndDate,
  reportStartDate,
  faultEquipmentCode,
  updateReport,
  defaultTab,
  defaultCollapse,
}) => {
  type Marker = {
    lat: number;
    lng: number;
    text: string;
  };
  const MarkerComponent = (marker: Marker) => (
    <Box
      sx={{
        display: 'inline-block',
        backgroundColor: 'red',
        width: '20px',
        height: '20px',
        marginLeft: '-10px',
        marginTop: '-10px',
        borderRadius: '50%',
      }}
    ></Box>
  );

  const COLLAPSE_NAME = {
    CONTROLLER_DETAILS: 'controllerDetails',
    BATTERY_GRAPH: 'batteryGraph',
  };
  const dispatch = useAppDispatch();
  const [values, setValues] = useState<Controller>(initialValues);
  const [openRegistersModal, setIsOpenRegistersModal] = useState<boolean>(false);
  const [isOpenControllerSettingsModal, setIsOpenControllerSettingsModal] = useState<boolean>(false);
  const [isOpenControllerEventHistoryModal, setIsOpenControllerEventHistoryModal] = useState<boolean>(false);
  const [selectTimezone, setSelectTimezone] = useState<string>(null);
  const closeModal = () => {
    if (defaultTab) {
      onChangeTab(defaultTab);
    }
    onCloseModal();
  };

  const [errors, setErrors] = useState(initialErrors);
  const [isChanged, setIsChanged] = useState(false);
  const { isLoading, editController } = useEditController();

  const { controllerClearDataFilterChanged } = useAppSelector((st) => st.controllers);
  const { customerAgreementCodes } = useAppSelector((st) => st.customers);
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const boxRef = useRef<HTMLDivElement>();
  const [isInstallDateEnabled, setIsInstallDateEnabled] = useState<boolean>(false);
  const { activeTab, onChangeTab, tabs } = useTabs(defaultTab);

  const { GOOGLE_MAP_KEY } = useAppSelector((st) => st.globalSlice);
  const [clickedMarker, setClickedMarker] = useState<Marker | null>(null);
  const [isEditingMap, setIsEditingMap] = useState<boolean>(false);
  const { isLoadingDashboardDetails, getCustomerDetailsById } = useDashboardDetails();
  const { getTimeZone, updateTimeZone, isLoadingTimezone } = useControllerTimezone();
  const { getCustomerAgreementCodes } = useGetCustomerAgreementCodes();
  const [allControllerDataLoaded, setAllControllerDataLoaded] = useState(false);
  const [isOwnTimezone, setIsOwnTimezone] = useState(false);

  const defaultProps = {
    center: {
      lat: values.latitudeDec ? values.latitudeDec : 31.758925371478234,
      lng: values.longitudeDec ? values.longitudeDec : 35.21191981022141,
    },
    zoom: 16,
  };
  const [mapCenter, setMapCenter] = useState(defaultProps.center);

  useEffect(() => {
    getCustomerAgreementCodes();
  }, []);
  useEffect(() => {
    if (currentController) {
      setCurrentControllerValues();
      if (openControllerEditModal) {
        updateCurrentController();
      }
    }
  }, [currentController, openControllerEditModal]);

  const setCurrentControllerValues = () => {
    setValues((prevValues) => ({
      ...prevValues,
      ['deviceId']: currentController.deviceId,
    }));
    setIsInstallDateEnabled(!currentController.installDate);
    setMapCenter({
      lat: currentController.latitudeDec ? currentController.latitudeDec : 31.758925371478234,
      lng: currentController.longitudeDec ? currentController.longitudeDec : 35.21191981022141,
    });
    if (currentController.latitudeDec && currentController.longitudeDec) {
      setClickedMarker({
        lat: currentController.latitudeDec,
        lng: currentController.longitudeDec,
        text: 'My Marker',
      });
    }
  };
  const onChangeValues = (event: any, propertyName: string, type?: string) => {
    const newValue = event.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      [propertyName]: newValue,
    }));
    setIsChanged(true);
  };
  const resetErrors = () => {
    setErrors(initialErrors);
  };
  const resetValues = () => {
    setValues(initialValues);
    setAllControllerDataLoaded(false);
  };
  useEffect(() => {
    if (defaultCollapse) {
      setOpenCollapse(defaultCollapse);
    } else if (isImplemented()) {
      setOpenCollapse([COLLAPSE_NAME.BATTERY_GRAPH]);
    } else {
      setOpenCollapse([COLLAPSE_NAME.CONTROLLER_DETAILS]);
    }

    if (!openControllerEditModal) {
      resetValues();
      resetErrors();
      setIsChanged(false);
      dispatch(clearDailyConsumption());
      setClickedMarker(null);
      setOpenCollapse([]);

      return;
    }
  }, [openControllerEditModal]);
  const updateCurrentController = async () => {
    if (!currentController) return;
    const updatetedCurrentController = await getCustomerDetailsById(currentController.deviceId);

    const timeZoneData = await getTimeZone(currentController.deviceId, currentController.customerId);
    setSelectTimezone(timeZoneData.data.timeZoneID);
    setIsOwnTimezone(timeZoneData.data.usingOwntimeZone);

    setValues((prevValues) => ({
      ...prevValues,
      ['deviceType']: updatetedCurrentController?.data[0].deviceType,
      ['powerSupCode']: updatetedCurrentController?.data[0].powerSupCode,
      ['customerAgreementStatus']: updatetedCurrentController?.data[0].customerAgreementStatus,
      ['modelId']: updatetedCurrentController?.data[0].modelID,
      ['objectName']: updatetedCurrentController?.data[0].objectName,
      // ['controllerName']: updatetedCurrentController?.data[0].objectName,
      ['customerName']: updatetedCurrentController?.data[0].customerName,
      ['customerId']: updatetedCurrentController?.data[0].customerID,
      ['hwId']: updatetedCurrentController?.data[0].hwid,
      ['deviceId']: updatetedCurrentController?.data[0].objectID,
      // ['stationId']: updatetedCurrentController?.data[0].
      //['gatewayId']: updatetedCurrentController?.data[0].
      // ['driverCode']: updatetedCurrentController?.data[0].
      // ['objStatus']: updatetedCurrentController?.data[0].
      // ['isObjectDisabled']: updatetedCurrentController?.data[0].
      ['objectNotes']: updatetedCurrentController?.data[0].notes,
      ['installAddress']: updatetedCurrentController?.data[0].installAddress,
      // ['gisHorisontal']: updatetedCurrentController?.data[0].
      // ['gisVertical']: updatetedCurrentController?.data[0].
      ['latitudeDec']: updatetedCurrentController?.data[0].latitude_Dec,
      ['longitudeDec']: updatetedCurrentController?.data[0].longitude_Dec,
      ['installDate']: updatetedCurrentController?.data[0].installDate,
      //['powerConnectDate']: updatetedCurrentController?.data[0].
    }));

    currentController = values;
    setAllControllerDataLoaded(true);
  };
  const [hasErrors, setHasErrors] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(defaultCollapse ? defaultCollapse : [COLLAPSE_NAME.BATTERY_GRAPH]);
  useEffect(() => {
    setHasErrors(!Object.values(errors).every((value) => value === ''));
  }, [errors]);
  const onChangeDateValues = (name: string, property: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: property,
    }));
    setIsChanged(true);
  };
  const handleSave = async () => {
    const result = await editController(values);
    if (result) {
      updateTimeZone({
        timeZoneID: parseInt(selectTimezone),
        controllerId: currentController.deviceId.toString(),
      });
      const timeZoneData = await getTimeZone(currentController.deviceId, currentController.customerId);
      setSelectTimezone(timeZoneData.data.timeZoneID);
      setIsOwnTimezone(timeZoneData.data.usingOwntimeZone);
    }
    setIsChanged(false);
  };

  const { installDateError, setInstallDateError } = useControllerDateErrors();
  const onSubmit = () => {
    if (!hasErrors) {
      handleSave();
    }
  };
  const handleCancel = () => {
    setCurrentControllerValues();
    resetErrors();
    setIsChanged(false);
  };
  const handleCloseEditModal = (e: React.MouseEvent<HTMLElement>) => {
    setOpenCollapse([]);
    if (isLoading) return;
    isChanged || controllerClearDataFilterChanged ? onOpenMenu(e) : closeModal();
  };

  const updateMarkerCoordinates = () => {
    const latitudeDec = values.latitudeDec.toString();
    const longitudeDec = values.longitudeDec.toString();
    setMapCenter({
      lat: parseFloat(latitudeDec),
      lng: parseFloat(longitudeDec),
    });
    setClickedMarker({
      lat: parseFloat(latitudeDec),
      lng: parseFloat(longitudeDec),
      text: 'My Marker',
    });
    setIsEditingMap(false);
  };
  const handleMapClick = ({ x, y, lat, lng, event }: any) => {
    if (!isEditingMap) return;
    setIsChanged(true);
    setClickedMarker({ lat, lng, text: 'My Marker' });
    setValues((prevValues) => ({
      ...prevValues,
      ['latitudeDec']: lat,
    }));
    setValues((prevValues) => ({
      ...prevValues,
      ['longitudeDec']: lng,
    }));
  };
  useEffect(() => {
    if (isChanged) {
      validateForm();
    }
  }, [values]);
  const checkObjectName = () => {
    const { objectName } = values;
    if (!objectName) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['objectName']: 'Please enter object name',
      }));
      return false;
    }
    if (objectName.length < 3) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['objectName']: 'Name must contain at least 3 characters',
      }));
      return false;
    }
    if (objectName.length > 50) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['objectName']: 'Max length 50 characters',
      }));
      return false;
    }

    setErrors((prevValues) => ({
      ...prevValues,
      ['objectName']: '',
    }));
    return true;
  };
  const checkInstallAdress = () => {
    const { installAddress } = values;
    if (installAddress && installAddress.length > 250) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['installAddress']: 'Max length 250 characters',
      }));
      return false;
    }
    setErrors((prevValues) => ({
      ...prevValues,
      ['installAddress']: '',
    }));
    return true;
  };
  const checkObjectNotes = () => {
    const { objectNotes } = values;
    if (objectNotes && objectNotes.length > 250) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['objectNotes']: 'Max length 250 characters',
      }));
      return false;
    }
    setErrors((prevValues) => ({
      ...prevValues,
      ['objectNotes']: '',
    }));
    return true;
  };
  const checkLatitudeDec = () => {
    const { latitudeDec } = values;

    if (isNaN(latitudeDec)) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['latitudeDec']: 'Not a number',
      }));
      return false;
    }

    if (latitudeDec < -90 || latitudeDec > 90) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['latitudeDec']: 'Please enter a value between -90 and 90',
      }));
      return false;
    }
    setErrors((prevValues) => ({
      ...prevValues,
      ['latitudeDec']: '',
    }));
    return true;
  };

  const checkLongitudeDec = () => {
    const { longitudeDec } = values;

    if (isNaN(longitudeDec)) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['longitudeDec']: 'Not a number',
      }));
      return false;
    }

    if (longitudeDec < -90 || longitudeDec > 90) {
      setErrors((prevValues) => ({
        ...prevValues,
        ['longitudeDec']: 'Please enter a value between -90 and 90',
      }));
      return false;
    }
    setErrors((prevValues) => ({
      ...prevValues,
      ['longitudeDec']: '',
    }));
    return true;
  };

  const validateForm = () => {
    checkObjectName();
    checkInstallAdress();
    checkObjectNotes();
    checkLatitudeDec();
    checkLongitudeDec();
  };
  const handleOpenControllerSettings = async () => {
    setIsOpenControllerSettingsModal(true);
  };
  const handleOpenControllerEventHistory = async () => {
    setIsOpenControllerEventHistoryModal(true);
  };

  const closeControllerSettingsModal = () => {
    setIsOpenControllerSettingsModal(false);
  };
  const closeControllerEventHistoryModal = () => {
    setIsOpenControllerEventHistoryModal(false);
  };
  const changedServiceCalls = () => {
    updateReport();
  };

  const setIsOpenGraph = (collapseName: string) => {
    let newSelectedCollapse = [...openCollapse];
    const index = newSelectedCollapse.findIndex((item) => item === collapseName);
    if (index != -1) {
      return;
    }
    newSelectedCollapse = [collapseName];
    setOpenCollapse(newSelectedCollapse);
  };
  const setIsOpenCollapse = (collapseName: string) => {
    let newSelectedCollapse = [...openCollapse];
    const index = newSelectedCollapse.findIndex((item) => item === collapseName);
    if (index != -1) {
      newSelectedCollapse.splice(index, 1);
      setOpenCollapse(newSelectedCollapse);
      return;
    }
    newSelectedCollapse = [collapseName];
    setOpenCollapse(newSelectedCollapse);
    // setOpenCollapse(collapseName);

    // if (collapseName === COLLAPSE_NAME.BATTERY_GRAPH) {
    //   setOpenCollapse(COLLAPSE_NAME.CONTROLLER_DETAILS);
    // }
    // if (collapseName === COLLAPSE_NAME.CONTROLLER_DETAILS) {
    //   setOpenCollapse(COLLAPSE_NAME.BATTERY_GRAPH);
    // }
  };

  const getCustomerAgreementName = (id: number) => {
    const index = customerAgreementCodes.findIndex((item) => item.code === id);
    return customerAgreementCodes[index].text;
  };

  const headerBaseColapse = () => {
    return <ControllerHeader currentController={values} />;
  };
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleModalClose = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };

  const isImplemented = () => {
    if (!currentController) {
      return false;
    }
    if (!currentController.modelId) return true;
    const implementedControllers = ['I1', 'IP'];
    return implementedControllers.includes(currentController.modelId);
  };
  const onChangeSelectTimezone = (timeZoneId: string) => {
    setSelectTimezone(timeZoneId);
    setIsChanged(true);
  };
  return (
    <Modal
      width={'100%'}
      padding={'8px 16px'}
      maxHeight={'995px'}
      height={'calc(100vh - 10px)'}
      open={openControllerEditModal}
      onClose={handleModalClose}
    >
      <Box margin={'0 10px'}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Row spacing="20px" alignItems="flex-start">
            <BigTitle>{values.objectName}</BigTitle>
          </Row>
          <Row sx={{ flexGrow: 1 }} />
          {showRegistersButton && (
            <Box>
              <BaseBtn onClick={handleOpenControllerEventHistory} sx={{ mr: '32px' }}>
                {t('event_history_button')}
                <Icon sx={{ ml: '6px' }} src={registers} />
              </BaseBtn>
              <BaseBtn onClick={handleOpenControllerSettings} sx={{ mr: '32px' }}>
                {t('controller_settings_button')}
                <Icon sx={{ ml: '6px' }} src={registers} />
              </BaseBtn>
            </Box>
          )}
          <IconButton ref={closeButtonRef} onClick={handleCloseEditModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>
        {values && (
          <Box component="form" autoComplete="off">
            <BaseCollapse
              isOpen={openCollapse.includes(COLLAPSE_NAME.CONTROLLER_DETAILS)}
              setIsOpen={(value) => setIsOpenCollapse(COLLAPSE_NAME.CONTROLLER_DETAILS)}
              header={headerBaseColapse()}
            >
              <Box sx={{ height: '100%' }}>
                <Row spacing="20px" alignItems="stretch" justifyContent="space-between" sx={{ height: '100%' }}>
                  <Box
                    width={'50%'}
                    sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', pb: '8px' }}
                  >
                    <Row spacing="20px" alignItems="start">
                      <Box width={'67%'}>
                        <Row spacing="20px" alignItems="start">
                          <Box width={'50%'}>
                            <BaseInput
                              label={`${t('controller_name_edit_modal')} *`}
                              name="objectName"
                              error={!!errors.objectName}
                              helperText={replaceSpacesWithUnderscores(errors.objectName)}
                              value={values?.objectName ?? ''}
                              onChange={(event) => onChangeValues(event, 'objectName')}
                              small
                              compact
                              blueBorder
                              marginBottom={8}
                            />
                          </Box>
                          <Box width={'50%'}>
                            <BaseTimezoneSelect
                              title={`${t('select_all_time_zone')}`}
                              onChange={onChangeSelectTimezone}
                              value={selectTimezone}
                              formInput
                              onlyActive
                            />
                            {openCollapse.includes(COLLAPSE_NAME.CONTROLLER_DETAILS) && (
                              <Box>
                                {!isOwnTimezone && selectTimezone && (
                                  <Box sx={{ color: 'orange', fontSize: '14px', position: 'absolute', zIndex: '0' }}>
                                    {t('using_the_default_time_zone_warning')}
                                  </Box>
                                )}
                                {!selectTimezone && (
                                  <Box sx={{ color: 'orange', fontSize: '14px', position: 'absolute', zIndex: '0' }}>
                                    {t('will_be_used_the_default_time_zone_warning')}
                                  </Box>
                                )}
                              </Box>
                            )}
                          </Box>
                        </Row>
                        <Row spacing="20px" alignItems="end" sx={{ mb: '4px', mt: '5px' }}>
                          <Box width={'50%'}>
                            <BaseDataPicker
                              label={`${t('operation_start_date_edit_modal')}`}
                              name="installDate"
                              value={values?.installDate}
                              onChange={onChangeDateValues}
                              disabled={!isInstallDateEnabled}
                              setError={setInstallDateError}
                              formStyle
                            />
                          </Box>
                          <Box width={'50%'}>
                            <BaseBtn
                              disabled={isInstallDateEnabled}
                              sx={{ height: '29px' }}
                              onClick={() => setIsInstallDateEnabled(true)}
                              btnColor="primary.dark"
                              fullWidth
                            >
                              {t('change_start_date_edit_modal')}
                            </BaseBtn>
                          </Box>
                        </Row>

                        <BaseInput
                          label={`${t('install_address_edit_modal')}`}
                          name="installAddress"
                          value={values?.installAddress ?? ''}
                          onChange={(event) => onChangeValues(event, 'installAddress')}
                          error={!!errors.installAddress}
                          helperText={replaceSpacesWithUnderscores(errors.installAddress)}
                          medium
                          compact
                        />

                        <BaseInput
                          label={`${t('objectNotes_edit_modal')}`}
                          name="objectNotes"
                          value={values?.objectNotes ?? ''}
                          onChange={(event) => onChangeValues(event, 'objectNotes')}
                          error={!!errors.objectNotes}
                          helperText={replaceSpacesWithUnderscores(errors.objectNotes)}
                          medium
                          compact
                        />
                      </Box>
                      <Box width={'33%'}>
                        <BaseInput
                          label={`${t('latitude_dec_edit_modal')}`}
                          name="latitudeDec"
                          error={!!errors.latitudeDec}
                          helperText={replaceSpacesWithUnderscores(errors.latitudeDec)}
                          value={values?.latitudeDec ?? ''}
                          onChange={(event) => onChangeValues(event, 'latitudeDec', 'number')}
                          small
                          compact
                          blueBorder
                          marginBottom={8}
                        />
                        <BaseInput
                          label={`${t('longitude_dec_edit_modal')}`}
                          name="longitudeDec"
                          error={!!errors.longitudeDec}
                          helperText={replaceSpacesWithUnderscores(errors.longitudeDec)}
                          value={values?.longitudeDec ?? ''}
                          onChange={(event) => onChangeValues(event, 'longitudeDec', 'number')}
                          small
                          compact
                          blueBorder
                        />
                        <BaseBtn
                          disabled={isEditingMap}
                          sx={{ height: '29px', mt: '16px' }}
                          onClick={() => setIsEditingMap(true)}
                          btnColor="primary.dark"
                          fullWidth
                        >
                          {t('edit_edit_modal')}
                        </BaseBtn>
                        <BaseBtn
                          disabled={!!errors.latitudeDec || !!errors.longitudeDec}
                          sx={{ height: '29px', mt: '16px' }}
                          onClick={() => updateMarkerCoordinates()}
                          btnColor="primary.dark"
                          fullWidth
                        >
                          {t('update_edit_modal')}
                        </BaseBtn>
                      </Box>
                    </Row>
                    <Row justifyContent="flex-start" sx={{ height: '36px', mt: '8px' }}>
                      <Row width={300}>
                        <BaseBtn onClick={handleCancel} btnColor="info.main" fullWidth>
                          {t('cancel_edit_modal')}
                        </BaseBtn>
                        <Box ref={boxRef} onClick={handleCancel} sx={{ height: '36px', width: '25px' }} />
                        <BaseBtn
                          onClick={onSubmit}
                          disabled={!isChanged || hasErrors}
                          loading={isLoading}
                          btnColor="primary.dark"
                          fullWidth
                        >
                          {t('save_edit_modal')}
                        </BaseBtn>
                      </Row>
                    </Row>
                  </Box>
                  <Box width={'50%'}>
                    {/* isEditingMap */}
                    <Box
                      sx={{
                        height: '345px',
                        width: '100%',
                        mt: '8px',
                        border: '4px solid ',
                        borderColor: isEditingMap ? 'rgb(10, 62, 144)' : 'white',
                      }}
                    >
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
                        center={mapCenter}
                        defaultZoom={defaultProps.zoom}
                        onClick={handleMapClick}
                      >
                        {clickedMarker && (
                          <MarkerComponent lat={clickedMarker.lat} lng={clickedMarker.lng} text={clickedMarker.text} />
                        )}
                      </GoogleMapReact>
                    </Box>
                  </Box>
                </Row>
              </Box>
            </BaseCollapse>
            <BatteryStatusInfo
              isOpenModal={openControllerEditModal}
              isOpen={openCollapse.includes(COLLAPSE_NAME.BATTERY_GRAPH)}
              setIsOpen={() => setIsOpenGraph(COLLAPSE_NAME.BATTERY_GRAPH)}
              toggleIsOpen={() => setIsOpenCollapse(COLLAPSE_NAME.BATTERY_GRAPH)}
              deviceId={values.deviceId}
              currentController={values}
              reportStartDate={reportStartDate}
              reportEndDate={reportEndDate}
              allControllerDataLoaded={allControllerDataLoaded}
              isImplemented={isImplemented()}
            />
          </Box>
        )}
        <Confirm
          open={isOpen}
          anchorEl={anchorEl}
          onClose={onCloseMenu}
          confirm={closeModal}
          buttonTexts={[t('cancel_edit_edit_modal'), t('back_to_edit_edit_modal')]}
        />

        {values && (
          <>
            <BaseTabs titles={tabs} activeTab={activeTab} changeTab={onChangeTab} />

            <Box sx={{ height: openCollapse.length ? '310px' : '620px', display: activeTab === 1 ? 'block' : 'none' }}>
              {currentController && (
                <LastIrrigationData
                  currentController={currentController}
                  showFull={!openCollapse.length}
                  allControllerDataLoaded={allControllerDataLoaded}
                />
              )}
            </Box>
            <Box sx={{ height: openCollapse.length ? '310px' : '620px', display: activeTab === 2 ? 'block' : 'none' }}>
              {activeTab === 2 && values.deviceId && (
                <ServiceCalls
                  changedServiceCalls={changedServiceCalls}
                  customerID={values.customerId}
                  customerName={values.customerName}
                  controllerID={values.deviceId}
                  controllerName={values.objectName}
                  faultEquipmentCode={faultEquipmentCode}
                  showFull={!openCollapse.length}
                  customerAgreementStatus={values.customerAgreementStatus}
                />
              )}
            </Box>
          </>
        )}
        <EditControllerClearDataModal currentController={currentController} />
      </Box>

      <ControllerSettingsModal
        isOpenModal={isOpenControllerSettingsModal}
        closeModal={closeControllerSettingsModal}
        currentController={values}
      />
      <ControllerEventHistory
        isOpenModal={isOpenControllerEventHistoryModal}
        closeModal={closeControllerEventHistoryModal}
        currentController={values}
      />
    </Modal>
  );
};
export const EditController = memo(Edit) as (props: Props) => React.ReactElement;
