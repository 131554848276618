const localStorageService = {
  get isRTL() {
    const valid=['en', 'he', 'ar', 'ar-sy', 'he-iss', 'en-iss', 'ar-SY-iss' ].includes(localStorage.getItem('agam_lang'))
    if(!valid) return true
    return ['he', 'ar'].includes(localStorage.getItem('agam_lang'));
  },

  get currentLang() {
    
    const valid=['en', 'he', 'ar', 'ar-sy', 'he-iss', 'en-iss', 'ar-SY-iss' ].includes(localStorage.getItem('agam_lang'))
    return valid ? localStorage.getItem('agam_lang') : 'he'
  },
  set currentLang(value: string) {
    localStorage.setItem('agam_lang', value);
  },

  get currentUserName() {
    return sessionStorage.getItem('agam_username');
  },

  set currentUserName(userName: string) {
    sessionStorage.setItem('agam_username', userName);
  },

  get currentUserMail() {
    return sessionStorage.getItem('agam_user_mail');
  },

  set currentUserMail(userName: string) {
    sessionStorage.setItem('agam_user_mail', userName);
  },

  get invalidToken() {
    const invalidTokenValue = sessionStorage.getItem('invalidToken');
    return invalidTokenValue ? JSON.parse(invalidTokenValue) : false;
  },

  set invalidToken(isInvalidToken: boolean) {
    if (!isInvalidToken) {
      sessionStorage.removeItem('invalidToken');
      return;
    }
    sessionStorage.setItem('invalidToken', JSON.stringify(isInvalidToken));
  },

  get userToken() {
    return sessionStorage.getItem('userToken');
  },

  set userToken(token: string) {
    if (!token) {
      sessionStorage.removeItem('userToken');
      return;
    }
    sessionStorage.setItem('userToken', token);
  },

  get replacedEquipmentReport() {
    return localStorage.getItem('replaced_equipment_report');
  },
  set replacedEquipmentReport(value: string) {
    localStorage.setItem('replaced_equipment_report', value);
  },

  get PowerProblemReport() {
    const data = localStorage.getItem('power_problem_report');
    try {
      return data && JSON.parse(data) ? data : null;
    } catch (e) {
      console.error('Invalid JSON in localStorage:', e);
      return null;
    }
  },
  set PowerProblemReport(value: string) {
    localStorage.setItem('power_problem_report', value);
  },
  get batteryStatusReport() {
    const data = localStorage.getItem('battery_status_report');
    try {
      return data && JSON.parse(data)? data : null;
    } catch (e) {
      console.error('Invalid JSON in localStorage:', e);
      return null;
    }
  },
  set batteryStatusReport(value: string) {
    localStorage.setItem('battery_status_report', value);
    
  },
  get loginDefaultPage() {
    return localStorage.getItem('dashboard_login_default');
  },
  set loginDefaultPage(value: string) {
    localStorage.setItem('dashboard_login_default', value);
  },
  get dashboardActiveCharts() {
    return localStorage.getItem('dashboard_active_charts');
  },
  set dashboardActiveCharts(value: string) {
    localStorage.setItem('dashboard_active_charts', value);
  },
  get activeTabEditController(){
    return localStorage.getItem('active_tab_edit_controller');
  },
  set activeTabEditController(value: string){
    localStorage.setItem('active_tab_edit_controller', value);
  },
  get itemsFieldsToExport(){
    const data = localStorage.getItem('items_fields_to_export');
    try {
      return data && JSON.parse(data)? data : null;
    } catch (e) {
      console.error('Invalid JSON in localStorage:', e);
      return null;
    }

  },
  set itemsFieldsToExport(value: string){
    localStorage.setItem('items_fields_to_export', value);
  },
  set resetPasswordByEmail(records:{userID:number, timestamp:number}[]){
    localStorage.setItem('password_reset_by_email', JSON.stringify(records));
  },
  get resetPasswordByEmail(){
    return JSON.parse(localStorage.getItem('password_reset_by_email'));
  },
  set resetPasswordByPhone(records:{userID:number, timestamp:number}[]){
    localStorage.setItem('password_reset_by_phone', JSON.stringify(records));
  },
  get resetPasswordByPhone(){
    return JSON.parse(localStorage.getItem('password_reset_by_phone'));
  },

  get returnUrl() {
    return sessionStorage.getItem('return_url');
  },

  set returnUrl(Url: string) {
    sessionStorage.setItem('return_url', Url);
  },

};

export default localStorageService;
