import useAxios from 'app/useAxios';
import { useTranslation } from 'react-i18next';
import { useToast } from 'shared/hooks';


type controllerTimeZoneUpdateData={
        timeZoneID:number,
        controllerId:string
}

export const useControllerTimezone = () => {
  const { loading, request } = useAxios();
  const { showError } = useToast();
  const { t } = useTranslation();




  return {

    getTimeZone: (controllerId: number, customerID: number) =>
      request<undefined, { data: {timeZoneID:string, usingOwntimeZone: boolean}; succeeded: boolean }>({
        method: 'get',
        showSuccessMessageCode: undefined,
        url: `/timeZone/controller?controllerId=${controllerId}&customerId=${customerID}`,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            return true;
          } else {
            showError(t('error_toast_text'));
          }
        },
      }),

    updateTimeZone: (data: controllerTimeZoneUpdateData) =>
      request<undefined, { succeeded: boolean }>({
        method: 'put',
        showSuccessMessageCode: undefined,
        url: `/timeZone/controller`,
        payload: data,
        onDataReceiveCompleted: (data) => {
          if (data.succeeded) {
            return true;
          } else {
            showError(t('update_failed_due_to_an_error'));
          }
        },
      }),

    loading,
    isLoadingTimezone: loading,
  };
};
