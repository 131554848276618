import { Box } from '@mui/material';
import { BaseTimezoneSelect } from 'components';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { BaseBtn, BigTitle, Row } from 'shared/ui';
import { useConfiguration } from './hooks';
import { useToast } from 'shared/hooks';

export const ConfigurationManager = () => {
  const [timeZoneID, setTimeZoneID] = useState<string>(null);
  const [isChanged, setIsChanged] = useState(false);

  const { getTimeZone, updateTimeZone, loading } = useConfiguration();
  const { showSuccess } = useToast();
  const onChange = (value: string) => {
    setTimeZoneID(value);
    setIsChanged(true);
  };
  // useEffect(() => {
  // 	setIsChanged
  // }, []);

  const initialConfiguration = async () => {
    const result = await getTimeZone();
    setTimeZoneID(result.data.timeZoneID);
  };

  useEffect(() => {
    initialConfiguration();
  }, []);
  const onSubmit = async () => {
    const result = await updateTimeZone({ timeZoneID: parseInt(timeZoneID) });
    if (result) {
      showSuccess(t('configuration_successfully_updated_toast_text'));
      setIsChanged(false);
    }
  };

  return (
    <>
      <Box>
        <Row sx={{ mb: '12px' }}>
          <BigTitle sx={{ mr: '25px' }}>{t('configuration_manager_title')}</BigTitle>
        </Row>
        <Row sx={{ mb: '12px' }}>
          <Box width={'100%'}>
            <Row sx={{ mb: '12px', alignItems: 'flex-end' }}>
              <BigTitle sx={{ mr: '25px', fontSize: '24px' }}>{t('timezone_configuration_manager')}</BigTitle>{' '}
              <Box width={'280px'}>
                <BaseTimezoneSelect
                  title={`${t('select_time_zone')}`}
                  onlyActive
                  onChange={onChange}
                  value={timeZoneID}
                  hideResetButton
                />
              </Box>
            </Row>
            <Row sx={{ mb: '12px', alignItems: 'flex-end' }}>
              <BaseBtn onClick={onSubmit} disabled={!isChanged} btnColor="primary.dark">
                {t('save_edit_modal')}
              </BaseBtn>
            </Row>
          </Box>
        </Row>
      </Box>
    </>
  );
};
