import { IconButton, Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { close_popup } from 'shared/assets';
import { useAppDispatch, useAppSelector, useAnchorEl } from 'shared/hooks';
import {
  toggleNetworkProtectionEditModal,
  setNodesConnections,
  setNodesPoints,
  setNodesLines,
  setNodesEndPoints,
  setCurrentNPControllerId,
} from 'shared/slices';
import { BigTitle, Modal, Row } from 'shared/ui';
import { BaseBtn, BaseInput } from '../../shared/ui';
import React from 'react';
import { Confirm } from 'shared/ui';
import { Box } from '@mui/material';
import { ICItemView } from 'shared/models';
import { NetworkProtectionMap } from 'entities_';
import { NPConnectedСontrollers } from 'features';
import { useNetworkProtectionManagement } from 'shared/api';
export const NetworkProtectionEditModal: FC = () => {
  const [isChanged, setIsChanged] = useState(false);
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { getNPControllerList } = useNetworkProtectionManagement();
  const { openNetworkProtectionEditModal } = useAppSelector((st) => st.modals);
  const { currentNPController, NPControllerList } = useAppSelector((st) => st.networkProtection);
  const closeModal = () => dispatch(toggleNetworkProtectionEditModal());
  useEffect(() => {
    const fetchData = async () => {
      getNPControllerList();
    };
    if (openNetworkProtectionEditModal) {
      fetchData();
    }
  }, [openNetworkProtectionEditModal]);
  const initialValues = {
    icProjectName: '',
    dstObjectName: '',
    dstModelID: '',
    dstVersion: '',
    icProjectID: 0,
    dstObjectCustomerID: 0,
    dstObjectID: 0,
    srcObjectID: 0,
    dstHWID: 0,
  };
  useEffect(() => {
    if (currentNPController) {
      setValues(currentNPController);
    }
    if (!openNetworkProtectionEditModal) {
      dispatch(setNodesPoints([]));
      dispatch(setNodesConnections([]));
      dispatch(setNodesLines([]));
      dispatch(setNodesEndPoints([]));
      dispatch(setCurrentNPControllerId(''));
    }
  }, [currentNPController, openNetworkProtectionEditModal]);
  const [values, setValues] = useState<ICItemView>(initialValues);

  const handleCloseEditModal = (e: React.MouseEvent<HTMLElement>) => {
    isChanged ? onOpenMenu(e) : closeModal();
  };

  return (
    <Modal
      width={'100%'}
      padding={'20px'}
      height={'calc(100vh - 20px)'}
      open={openNetworkProtectionEditModal}
      onClose={handleCloseEditModal}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BigTitle>{t('edit_network_protection_controller_modal_title')}</BigTitle>
        <IconButton onClick={handleCloseEditModal}>
          <img src={close_popup} />
        </IconButton>
      </Stack>
      {values && (
        <Box component="form" autoComplete="off">
          <Row spacing="20px" alignItems="flex-start" justifyContent="space-between">
            <Box width={'50%'}>
              <Row spacing="20px" alignItems="flex-start" justifyContent="space-between" width={'100%'}>
                <Box width={'66%'}>
                  <BaseInput
                    label={`${t('ic_project_name_np_edit_modal')}`}
                    name="icProjectName"
                    disabled
                    helperText=""
                    value={values?.icProjectName ?? ''}
                    small
                    compact
                  />
                  <BaseInput
                    label={`${t('dst_object_name_np_edit_modal')}`}
                    name="dstObjectName"
                    disabled
                    helperText=""
                    value={values?.dstObjectName ?? ''}
                    small
                    compact
                  />
                  <BaseInput
                    label={`${t('dst_model_id_np_edit_modal')}`}
                    name="dstModelID"
                    disabled
                    helperText=""
                    value={values?.dstModelID ?? ''}
                    small
                    compact
                  />
                  <BaseInput
                    label={`${t('dst_version_np_edit_modal')}`}
                    name="dstVersion"
                    disabled
                    helperText=""
                    value={values?.dstVersion ?? ''}
                    small
                    compact
                  />
                </Box>
                <Box width={'32%'}>
                  <BaseInput
                    label={`${t('ic_project_id_np_edit_modal')}`}
                    name="icProjectID"
                    disabled
                    helperText=""
                    value={values?.icProjectID ?? ''}
                    small
                    compact
                  />
                  <BaseInput
                    label={`${t('dst_object_customer_id_np_edit_modal')}`}
                    name="dstObjectCustomerID"
                    disabled
                    helperText=""
                    value={values?.dstObjectCustomerID ?? ''}
                    small
                    compact
                  />
                  <BaseInput
                    label={`${t('dst_object_id_np_edit_modal')}`}
                    name="dstObjectID"
                    disabled
                    helperText=""
                    value={values?.dstObjectID ?? ''}
                    small
                    compact
                  />
                  <BaseInput
                    label={`${t('dst_HWID_np_edit_modal')}`}
                    name="dstHWID"
                    disabled
                    helperText=""
                    value={values?.dstHWID ?? ''}
                    small
                    compact
                  />
                </Box>
              </Row>
              <Box width={'100%'}>
                <NPConnectedСontrollers controllers={NPControllerList} />
              </Box>
            </Box>
            <Box width={'50%'}>
              {openNetworkProtectionEditModal && (
                <NetworkProtectionMap onlyOneProtectionUnit={NPControllerList.length === 1} />
              )}
            </Box>
          </Row>

          <Row justifyContent="flex-end" mt={'5px'}>
            <Row width={300}>
              <BaseBtn onClick={handleCloseEditModal} fullWidth>
                {t('ok_edit_modal')}
              </BaseBtn>
            </Row>
          </Row>
        </Box>
      )}
    </Modal>
  );
};
