import { Box, CircularProgress } from '@mui/material';
import { LineBarChart } from 'components/LineBarChart';
import { RainData } from 'features/WeatherStationsManager/WeatherStationModels';
import { t } from 'i18next';
import { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'shared/hooks';
import { BigTitle, Row } from 'shared/ui';

interface Props {
  loading: boolean;
  startReportDate: string;
  endReportDate: string;
  maxEvaporationRate: number;
  newValueMaxEvaporationRate: number;
  updateSeasonalCoefficient: (array: number[][]) => void;
}

const CHAT_COLORS = [
  {
    stroke: '#cc5050',
    fill: '#cc5050dd',
  },
  {
    stroke: '#6600ff',
    fill: '#00000000',
  },
];

export const IrrigationFactorData: FC<Props> = ({
  loading,
  startReportDate,
  endReportDate,
  newValueMaxEvaporationRate,
  maxEvaporationRate,
  updateSeasonalCoefficient,
}) => {
  const [popupPadding, setPopupPadding] = useState(null);
  const { irrigationFactorData, evaporationAndRainData } = useAppSelector((st) => st.weatherStation);
  const { WEATHER_STATIONS } = useAppSelector((st) => st.globalSlice);
  const [formattedData, setFormattedData] = useState([]);
  const [seasonalCoefficient, setSeasonalCoefficient] = useState<number[]>([]);

  useEffect(() => {
    const timestamps: number[] = [];
    const targetFactor = [null];
    if (irrigationFactorData.length) {
      const firstTimeStamp = new Date(startReportDate).getTime();

      timestamps.push(firstTimeStamp / 1000 - 50000);

      irrigationFactorData.forEach((item) => {
        const timestamp = new Date(item.date_).getTime();

        timestamps.push(timestamp / 1000);
        // eTValues.push(item.etValue)
        targetFactor.push(item.targetFactor);
      });
      targetFactor.push(null);
      const lastTimeStamp = new Date(endReportDate).getTime();
      timestamps.push(lastTimeStamp / 1000 + 50000);
    }

    if (!targetFactor.some((value) => value !== null && value !== 0)) {
      const data = [null, targetFactor, []];
      setFormattedData(data);
      return;
    }

    const data = [timestamps, targetFactor, []];
    if (seasonalCoefficient.length) {
      const data = [timestamps, targetFactor, seasonalCoefficient];
      updateSeasonalCoefficient([timestamps, seasonalCoefficient]);
      setFormattedData(data);
      return;
    }
    updateSeasonalCoefficient([[], []]);
    setFormattedData(data);
  }, [irrigationFactorData, seasonalCoefficient]);

  useEffect(() => {
    const averagedData = calculate7DayAverage(evaporationAndRainData);
    let seasonalCoefficient = [];
    if (maxEvaporationRate != newValueMaxEvaporationRate) {
      seasonalCoefficient = transformData(averagedData, newValueMaxEvaporationRate);
    }
    setSeasonalCoefficient(seasonalCoefficient);
  }, [evaporationAndRainData, newValueMaxEvaporationRate, loading]);

  useEffect(() => {
    function handleResize() {
      setTimeout(() => {
        setPopupPadding(window.innerWidth * 0.35 + 10);
      }, 0);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const calculate7DayAverage = (arr) => {
    const dayPeriodParam = WEATHER_STATIONS.DAY_PERIOD_PARAM; // Кількість днів для розрахунку середнього
    const minValidRange = WEATHER_STATIONS.VALID_MEASUREMENT_RANGE[0];
    const maxValidRange = WEATHER_STATIONS.VALID_MEASUREMENT_RANGE[1];

    const result = [];

    for (let i = 0; i < arr.length; i++) {
      if (i < dayPeriodParam - 1) {
        result.push({
          date_: arr[i].date_,
          averageEtValue: null,
        });
      } else {
        const slice = arr.slice(i - dayPeriodParam + 1, i + 1);
        const etValues = slice
          .map((item) => item.etValue)
          .filter((value) => value !== null && value >= minValidRange && value <= maxValidRange);
        const averageEtValue =
          etValues.length >= 2 ? etValues.reduce((acc, val) => acc + val, 0) / etValues.length : null;
        result.push({
          date_: arr[i].date_,
          averageEtValue: averageEtValue,
        });
      }
    }

    return result;
  };

  const transformData = (data, multiplier) => {
    const adjustedValues = [];

    data.forEach((item) => {
      let adjustedValue = item.averageEtValue !== null ? Math.round((item.averageEtValue / multiplier) * 100) : null;

      if (adjustedValue !== null) {
        adjustedValue = Math.max(10, Math.min(adjustedValue, 100));
      }

      adjustedValues.push(adjustedValue);
    });

    adjustedValues.unshift(null);
    adjustedValues.push(null);
    return adjustedValues;
  };

  return (
    <>
      <Box sx={{ display: 'block', width: '100%', position: 'relative' }}>
        <Box sx={{ textAlign: 'center' }}>{t('seasonal_coefficient_chart_title')}</Box>

        <Box>
          {loading ? (
            <Row height={260} justifyContent="center" margin="20px auto">
              <CircularProgress disableShrink />
            </Row>
          ) : (
            <LineBarChart
              data={formattedData}
              startDate={startReportDate}
              endDate={endReportDate}
              popupPadding={popupPadding}
              height={300}
              chartTypes={['bars', 'linear']}
              chartColors={CHAT_COLORS}
              pointTextPosition={7}
              leftLabel={t('percent_of_irrigation_line_bar_chart_label')}
              minMaxYScales={[0, 110]}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
