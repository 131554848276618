import { Box, IconButton, Stack } from '@mui/material';
import { BaseDataAndTimePicker } from 'features/BaseDataAndTimePicker';
import { useTimeZone } from 'features/TimeZoneManager/hooks';
import { TimeZone, UpdateTimeZone } from 'features/TimeZoneManager/timeZoneModels';
import { AssignedCustomer } from 'features/WeatherStationsManager/WeatherStationModels';
import { t } from 'i18next';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { close_popup } from 'shared/assets';
import { BaseBtn, BaseCheckbox, BaseInput, BigTitle, Confirm, Modal, Row } from 'shared/ui';
import { useAnchorEl, useAppSelector, useToast } from 'shared/hooks';
import { IsSyncConfirmModal } from '../IsSyncConfirmModal';
import { IsSingleSyncDataModal, MessageModal } from '..';

interface Props {
  openModal: boolean;
  onCloseModal: () => void;
  timeZone: TimeZone;
  updateTimeZoneData: () => void;
}

export const TimeZoneEditModal: FC<Props> = ({ openModal, onCloseModal, timeZone, updateTimeZoneData }) => {
  const { syncTimeZone, updateTimeZone, getTimeZone, isLoading } = useTimeZone();
  const [newZoneStart, setNewZoneStart] = useState(null);
  const [newZoneEnd, setNewZoneEnd] = useState(null);
  const [utcOffset, setUtcOffset] = useState(0);
  const [isTimeZoneChanged, setIsTimeZoneChanged] = useState(false);
  const [isValidZoneStart, setIsValidZoneStart] = useState('');
  const [isValidZoneEnd, setIsValidZoneEnd] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [zoneName, setZoneName] = useState('');
  const [isSync, setIsSync] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isDST, setIsDST] = useState<boolean>(false);
  const [hasDST, setHasDST] = useState<boolean>(false);

  const [showIsSyncConfirm, setShowIsSyncConfirm] = useState<boolean>(false);
  const [isZoneNameUnique, setIsZoneNameUnique] = useState(true);
  const [showIsSingleSyncDataModal, setShowIsSingleSyncDataModal] = useState<boolean>(false);
  const [showIsMessageModal, setShowIsMessageModal] = useState<boolean>(false);
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useAnchorEl();
  const { timeZones } = useAppSelector((st) => st.timeZone);
  const onSyncData = async () => {
    const { data } = await syncTimeZone(timeZone.zoneName);
    if (data) {
      const { dst, zoneStart, zoneEnd, gmtOffset } = data;

      const newZoneStar = zoneStart ? new Date(zoneStart * 1000) : null;
      const newZoneEnd = zoneEnd ? new Date(zoneEnd * 1000) : null;
      const newUtcOffset = gmtOffset ? gmtOffset / 3600 : 0;
      setHasDST(false);
      if (zoneStart && zoneEnd) {
        setHasDST(true);
      }
      setIsDST(!!dst);
      setNewZoneStart(newZoneStar);
      setNewZoneEnd(newZoneEnd);
      setUtcOffset(newUtcOffset);
      setIsTimeZoneChanged(true);
      setDisplayName(timeZone.displayName);
      setZoneName(timeZone.zoneName);
      setIsSync(!!timeZone.isSync);
      setIsActive(!!timeZone.isActive);
      setIsZoneNameUnique(true);
    }
  };

  useEffect(() => {
    checkDatePassed();
  }, [newZoneEnd, newZoneStart]);

  const checkDatePassed = () => {
    const start = new Date(newZoneStart);
    const end = new Date(newZoneEnd);
    const now = new Date();

    if (end > now) {
      setIsValidZoneEnd('');
    } else {
      setIsValidZoneEnd(t('date_has_already_passed'));
    }
    if (start > now) {
      setIsValidZoneStart('');
    } else {
      setIsValidZoneStart(t('date_has_already_passed'));
    }
  };
  const changeNewZoneStart = (value) => {
    setNewZoneStart(value);
    setIsTimeZoneChanged(true);
  };
  const changeNewZoneEnd = (value) => {
    setNewZoneEnd(value);
    setIsTimeZoneChanged(true);
  };
  const onChangeUtcOffset = (value) => {
    const val = value.target.value;
    if (val < -11 || val > 11) return;
    setUtcOffset(val);
    setIsTimeZoneChanged(true);
  };

  const changeCheckboxIsActive = (e: ChangeEvent<HTMLInputElement>) => {
    setIsTimeZoneChanged(true);
    setIsActive(e.target.checked);
  };
  const changeCheckboxIsSync = (e: ChangeEvent<HTMLInputElement>) => {
    if (timeZone.isManual) {
      setShowIsMessageModal(true);
      return;
    }

    if (e.target.checked) setShowIsSyncConfirm(true);
    //setIsTimeZoneChanged(true)
    setIsSync(e.target.checked);
  };

  const changeCheckboxIsDST = (e: ChangeEvent<HTMLInputElement>) => {
    if (isSync) return;
    setIsTimeZoneChanged(true);
    setIsDST(e.target.checked);
  };
  const changeHasDst = (e: ChangeEvent<HTMLInputElement>) => {
    if (isSync) return;
    setIsTimeZoneChanged(true);
    setHasDST(e.target.checked);
  };
  const checkIsZonePeriodIsValid = (newZoneStart: Date, newZoneEnd: Date) => {
    const start = new Date(newZoneStart);
    const end = new Date(newZoneEnd);
    if (start > end) {
      setIsValidZoneStart(t('dst_period_is_not_valid'));
      setIsValidZoneEnd(t('dst_period_is_not_valid'));
      return false;
    } else {
      return true;
    }
  };
  const initialData = () => {
    if (timeZone) {
      const zoneStar = timeZone.zoneStart ? new Date(timeZone.zoneStart * 1000) : null;
      const zoneEnd = timeZone.zoneEnd ? new Date(timeZone.zoneEnd * 1000) : null;
      const utcOffset = timeZone.utcOffset ? timeZone.utcOffset / 3600 : 0;
      setHasDST(!!timeZone.hasDST);
      setIsSync(!!timeZone.isSync);
      setIsActive(!!timeZone.isActive);
      setIsDST(!!timeZone.dst);
      setNewZoneStart(zoneStar);
      setNewZoneEnd(zoneEnd);
      setUtcOffset(utcOffset);
      setDisplayName(timeZone.displayName);
      setZoneName(timeZone.zoneName);
      setIsZoneNameUnique(true);
    }
  };
  const checkForUniqueDisplayName = () => {
    if (timeZone.zoneName === zoneName) return true;
    return !timeZones.some((zone) => zone.zoneName === zoneName);
  };
  const onUpdateTimeZone = async () => {
    const checkNameResult = checkForUniqueDisplayName();
    if (!checkNameResult) {
      setIsZoneNameUnique(false);
      return;
    }

    const data: UpdateTimeZone = {
      timeZoneID: timeZone.timeZoneID,
      uTCOffset: utcOffset * 3600,
      dst: isDST,
      isActive: isActive,
      isSync: isSync,
      hasDST: hasDST,
      zoneName: zoneName,
      displayName: displayName,
    };
    let isValid = true;
    if (newZoneStart) {
      const localDate = new Date(newZoneStart);
      const utcTimestamp = Math.floor(localDate.getTime() / 1000);

      data.zoneStart = utcTimestamp;
    }
    if (newZoneEnd) {
      const localDate = new Date(newZoneEnd);
      const utcTimestamp = Math.floor(localDate.getTime() / 1000);

      data.zoneEnd = utcTimestamp;
      if (newZoneStart) {
        isValid = checkIsZonePeriodIsValid(newZoneStart, newZoneEnd);
      }
    }
    if (!isValid) return;
    const result = await updateTimeZone(data);
    if (result) {
      updateTimeZoneData();
      onCloseModal();
    }
  };
  const clearData = () => {
    setIsTimeZoneChanged(false);
    setHasDST(false);
    setIsSync(false);
    setIsActive(true);
    setIsDST(false);
    setNewZoneStart(null);
    setNewZoneEnd(null);
    setUtcOffset(0);
    setDisplayName('');
    setZoneName('');
    setIsValidZoneStart('');
    setIsValidZoneEnd('');
  };
  useEffect(() => {
    if (openModal) {
      initialData();
    } else {
      clearData();
    }
  }, [timeZone, openModal]);

  const handleCloseEditModal = (e: React.MouseEvent<HTMLElement>) => {
    // if (isLoading) return;
    // setCreatedDateError(null);
    // setStartDateError(null);

    isTimeZoneChanged ? onOpenMenu(e) : onCloseModal();
  };

  const closeIsSyncConfirmModal = () => {
    setShowIsSyncConfirm(false);
    setIsSync(false);
  };
  const confirmIsSync = () => {
    setShowIsSyncConfirm(false);
    setIsTimeZoneChanged(true);
  };

  const closeIsSingleSyncDataModal = () => {
    setShowIsSingleSyncDataModal(false);
  };
  const confirmIsSingleSyncData = () => {
    setShowIsSingleSyncDataModal(false);
    setIsTimeZoneChanged(true);
    clearData();
    onSyncData();
  };
  const openIsSingleSyncDataModal = () => {
    setShowIsSingleSyncDataModal(true);
  };
  const changeDisplayName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDisplayName(e.target.value);
  };
  const changeZoneName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsZoneNameUnique(true);
    setZoneName(e.target.value);
  };
  const isSaveDisabled = () => {
    return !displayName || !zoneName || (hasDST && !newZoneStart) || (hasDST && !newZoneEnd);
  };
  return (
    <Modal open={openModal} sx={{ margin: 'auto', width: '680px' }} onClose={handleCloseEditModal} padding={'8px 16px'}>
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BigTitle>{t('time_zone_edit_title')} </BigTitle>
          <IconButton onClick={handleCloseEditModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>
        {timeZone && (
          <Box>
            {/* <Box>{timeZone.isSync}</Box>
					<Box>{timeZone.isActive}</Box> */}
            <Row spacing="15px" sx={{ justifyContent: 'space-between', alignItems: 'flex-start', minHeight: '280px' }}>
              <Box width="50%">
                <BaseInput
                  label={`${t('display_name_time_zone_edit')}`}
                  name="display_name"
                  value={displayName}
                  onChange={changeDisplayName}
                  error={false}
                  helperText={''}
                  disabled={!timeZone.isManual}
                  small
                  compact
                  smallLabel
                  marginRight
                />
                <BaseCheckbox
                  label={t(`is_auto_sync_time_zone_edit`)}
                  value={isSync}
                  onChange={(e) => changeCheckboxIsSync(e)}
                />
                <BaseCheckbox
                  label={t(`has_dst_time_zone_edit`)}
                  value={hasDST}
                  disabled={isSync}
                  onChange={(e) => changeHasDst(e)}
                />
                <Box width="255px">
                  {hasDST && (
                    <BaseDataAndTimePicker
                      label={`${t('dst_start_time_zone_edit')}`}
                      name="dstStart"
                      value={newZoneStart}
                      onChange={(name, value) => {
                        changeNewZoneStart(value);
                      }}
                      changedText={(value) => {
                        changeNewZoneStart(value);
                      }}
                      showFuture
                      showError={!!isValidZoneStart}
                      errorMessage={isValidZoneStart}
                      disabled={isSync}
                    />
                  )}
                </Box>

                {hasDST && (
                  <BaseCheckbox
                    label={t(`is_dst_time_zone_edit`)}
                    value={isDST}
                    onChange={(e) => changeCheckboxIsDST(e)}
                    disabled={isSync}
                  />
                )}
                <BaseCheckbox
                  label={t(`is_active_in_application_time_zone_edit`)}
                  value={isActive}
                  onChange={(e) => changeCheckboxIsActive(e)}
                />
              </Box>
              <Box width="50%">
                <BaseInput
                  label={`${t('zone_name_time_zone_edit')}`}
                  name="zone_name"
                  value={zoneName}
                  onChange={changeZoneName}
                  error={isZoneNameUnique}
                  disabled={!timeZone.isManual}
                  small
                  compact
                  smallLabel
                  marginRight
                  helperText={isZoneNameUnique ? '' : 'zone_name_must_be_unique'}
                />
                {!timeZone.isManual && (
                  <BaseBtn
                    loading={isLoading}
                    sx={{ mt: '16px', height: '29px', width: '255px' }}
                    onClick={openIsSingleSyncDataModal}
                    fullWidth
                  >
                    {t('sync_data_zone_edit_modal')}
                  </BaseBtn>
                )}
                <Box sx={{ width: '255px', height: '43px' }}></Box>
                {timeZone.isManual && <Box sx={{ width: '255px', height: '44px' }}></Box>}
                <Box width="255px">
                  {hasDST && (
                    <BaseDataAndTimePicker
                      label={`${t('dst_end_time_zone_edit')}`}
                      name="dstEnd"
                      value={newZoneEnd}
                      onChange={(name, value) => {
                        changeNewZoneEnd(value);
                      }}
                      changedText={(value) => {
                        changeNewZoneEnd(value);
                      }}
                      showFuture
                      showError={!!isValidZoneEnd}
                      errorMessage={isValidZoneEnd}
                      disabled={isSync}
                    />
                  )}
                </Box>
                <BaseInput
                  label={`${t('utc_offset_time_zone_edit')}`}
                  name="utc_offset"
                  value={utcOffset}
                  onChange={onChangeUtcOffset}
                  error={false}
                  helperText={''}
                  type="number"
                  small
                  compact
                  smallLabel
                  marginRight
                  valueForNumberChanging={1}
                  disabled={isSync}
                />
              </Box>
            </Row>
          </Box>
        )}
        <Row justifyContent="flex-end" sx={{ marginTop: '16px' }}>
          <Row width={300} gap="10px">
            <BaseBtn onClick={handleCloseEditModal} btnColor="info.main" fullWidth>
              {t('cancel_time_zone_edit_modal')}
            </BaseBtn>
            <BaseBtn disabled={isSaveDisabled()} onClick={onUpdateTimeZone} fullWidth>
              {t('save_time_zone_edit_modal')}
            </BaseBtn>
          </Row>
        </Row>
      </Box>
      <Confirm
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        confirm={onCloseModal}
        buttonTexts={[t('cancel_edit_edit_modal'), t('back_to_edit_edit_modal')]}
      />
      <IsSyncConfirmModal
        openModal={showIsSyncConfirm}
        onCloseModal={closeIsSyncConfirmModal}
        onConfirm={confirmIsSync}
      />
      <IsSingleSyncDataModal
        openModal={showIsSingleSyncDataModal}
        onCloseModal={closeIsSingleSyncDataModal}
        onConfirm={confirmIsSingleSyncData}
      />
      <MessageModal
        openModal={showIsMessageModal}
        onCloseModal={() => setShowIsMessageModal(false)}
        title={t('is_auto_sync_title')}
        message={t('cannot_be_synchronized_message')}
      />
    </Modal>
  );
};
