import { Box, Hidden, IconButton, Stack } from '@mui/material';
import { AssignedCustomer } from 'features/WeatherStationsManager/WeatherStationModels';
import { t } from 'i18next';
import { FC } from 'react';
import { close_popup } from 'shared/assets';
import { BaseBtn, BigTitle, Modal, Row, Text } from 'shared/ui';

export type CustomerItem = {
  value: string;
  label: string;
  isMainCustomer: boolean;
};

interface Props {
  openModal: boolean;
  onCloseModal: () => void;
  confirmed: () => void;
}

export const LinkedControllersConfirm: FC<Props> = ({ openModal, onCloseModal, confirmed }) => {
  return (
    <Modal open={openModal} onClose={onCloseModal} padding={'8px 16px'}>
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BigTitle>{t('confirm_message_link_controller_to_this_station')}</BigTitle>
          <IconButton onClick={onCloseModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>
        <Box sx={{ overflow: 'hidden', width: '100%', wordBreak: 'break-all' }}>{t('')}</Box>
        <Box sx={{ overflow: 'hidden', m: '16px 0px', width: '100%', wordBreak: 'break-all' }}>
          {t('are_you_sure_link_controller_to_this_station')}
        </Box>
        <Row justifyContent="center">
          <Row width={300} gap="10px">
            <BaseBtn onClick={onCloseModal} btnColor="info.main" fullWidth>
              {t('cancel_confirm_link_controller_to_this_station')}
            </BaseBtn>
            <BaseBtn type="submit" onClick={confirmed} btnColor="primary.dark" fullWidth>
              {t('confirm_link_controller_to_this_station')}
            </BaseBtn>
          </Row>
        </Row>
      </Box>
    </Modal>
  );
};
