import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TimeZone } from './timeZoneModels';

interface TimeZoneState {
  timeZones: TimeZone[]
}

const initialState: TimeZoneState = {
  timeZones: []
};

const timeZoneSlice = createSlice({
  name: 'TimeZone',
  initialState,
  reducers: {
    setTimeZones: (state, action: PayloadAction<TimeZone[]>) => {
      state.timeZones = action.payload;
    },

  },
});

export const {
  setTimeZones
} = timeZoneSlice.actions;

export const timeZoneReducer = timeZoneSlice.reducer;
