import { Box } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from 'react';
interface Props {
  onChangeCoordinates: ({ lat, lng }: { lat: number; lng: number }) => void;
  value: { lat: number; lng: number };
  mapKey: string;
}
type Marker = {
  lat: number;
  lng: number;
  text: string;
};
export const ISSMap = ({ onChangeCoordinates, value, mapKey }: Props) => {
  const [isEditingMap, setIsEditingMap] = useState<boolean>(true);
  const [clickedMarker, setClickedMarker] = useState<Marker | null>(null);
  const defaultProps = {
    zoom: 16,
  };

  const MarkerComponent = (marker: Marker) => (
    <Box
      sx={{
        display: 'inline-block',
        backgroundColor: 'red',
        width: '20px',
        height: '20px',
        marginLeft: '-10px',
        marginTop: '-10px',
        borderRadius: '50%',
      }}
    ></Box>
  );
  const [mapCenter, setMapCenter] = useState({
    lat: value.lat,
    lng: value.lng,
  });

  useEffect(() => {
    //getCoordinates();
    setNewCoordinates(value.lat, value.lng);
  }, []);

  useEffect(() => {
    setClickedMarker({ lat: value.lat, lng: value.lng, text: 'My Marker' });
    setMapCenter({ lat: value.lat, lng: value.lng });
  }, [value]);
  const setNewCoordinates = (latitude: number, longitude: number) => {
    setClickedMarker({ lat: latitude, lng: longitude, text: 'My Marker' });
    onChangeCoordinates({ lat: latitude, lng: longitude });
  };
  const handleMapClick = ({ x, y, lat, lng, event }: any) => {
    if (!isEditingMap) return;
    setNewCoordinates(lat, lng);
  };

  return (
    <Box
      sx={{
        height: '345px',
        width: '100%',
        mt: '8px',
        borderRadius: '40px',
      }}
    >
      {mapKey && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: mapKey }}
          center={mapCenter}
          defaultZoom={defaultProps.zoom}
          onClick={handleMapClick}
        >
          {clickedMarker && (
            <MarkerComponent lat={clickedMarker.lat} lng={clickedMarker.lng} text={clickedMarker.text} />
          )}
        </GoogleMapReact>
      )}
    </Box>
  );
};
