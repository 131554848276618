import React from 'react';
import { createTheme, CssBaseline, Direction, ThemeProvider as MUIThemeProvider } from '@mui/material';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { MUIDirectionContext } from 'shared/contexts';
import { useExtendedTranslation } from 'shared/hooks';
import localStorageService from 'shared/localStorage';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});
const emptyCache = createCache({
  key: 'meaningless-key',
});

export const withTheme = (component: () => React.ReactNode) => () => {
  const [direction, setDirection] = React.useState<Direction>('rtl');

  const muiWrapperUtils = React.useMemo(
    () => ({
      changeDirection: (dir: Direction) => setDirection(dir),
    }),
    []
  );
  const { changeLanguage } = useExtendedTranslation();

  React.useEffect(() => {
    const { currentLang, isRTL } = localStorageService;
    console.log('lang', currentLang, isRTL);

    if (!currentLang) {
      changeLanguage('he');
      return;
    }

    isRTL ? setDirection('rtl') : setDirection('ltr');
  }, []);

  React.useEffect(() => {
    document.dir = direction;
  }, [direction]);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            light: 'rgb(90, 147, 211)',
            contrastText: 'rgb(59, 115, 187)',
            main: 'rgb(36, 90, 165)',
            dark: 'rgb(10, 62, 144)',
          },
          secondary: {
            main: 'rgba(61, 134, 190, 0.15)',
            light: 'rgba(199, 199, 199, 1)',
            contrastText: 'rgb(226, 237, 245)',
            dark: 'rgb(0, 0, 0)',
          },
          info: {
            main: 'rgba(255, 255, 255, 1)',
            light: 'rgba(246, 246, 246, 1)',
            dark: 'rgba(122, 122, 122, 1)',
            contrastText: 'rgba(61, 134, 190, 0.15)',
          },
          success: {
            main: 'rgba(22, 171, 52, 1)',
            light: 'rgba(222, 247, 236, 1)',
          },
          error: {
            dark: 'rgba(153, 27, 27, 1)',
            main: 'rgba(229, 84, 84, 1)',
            light: 'rgba(254, 226, 226, 1)',
          },
          warning: {
            main: 'rgba(154, 52, 18, 1)',
            light: 'rgba(255, 237, 213, 1)',
          },
        },
        typography: {
          fontFamily: 'Noto Sans Hebrew',
        },
        direction,
      }),
    [direction]
  );

  return (
    <CacheProvider value={direction === 'rtl' ? cacheRtl : emptyCache}>
      <MUIDirectionContext.Provider value={muiWrapperUtils}>
        <MUIThemeProvider theme={theme}>
          <CssBaseline />
          {component()}
        </MUIThemeProvider>
      </MUIDirectionContext.Provider>
    </CacheProvider>
  );
};
