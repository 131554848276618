import { t } from 'i18next';
import { useState } from 'react';
export const useFilter = () => {

  const [statusTimeZone, setStatusTimeZone] = useState<string>('');
  const [isSyncTimeZone, setIsSyncTimeZone] = useState<string>('');


  const timeZoneStatusItems = [
    {
      value: '1',
      label: t('time_zone_status_active'),
    },
    {
      value: '2',
      label: t('time_zone_status_not_active'),
    }

  ];

  const timeZoneIsSyncItems = [
    {
      value: '1',
      label: t('time_zone_status_sync'),
    },
    {
      value: '2',
      label: t('time_zone_status_not_sync'),
    }

  ];

  const onChangeStatusTimeZone = (value: string) => {
    setStatusTimeZone(value);
  };
  
  const onChangeIsSyncTimeZone = (value: string) => {
    setIsSyncTimeZone(value);
  };

  return {
		timeZoneStatusItems,
		onChangeStatusTimeZone,
		statusTimeZone,
    isSyncTimeZone,
    onChangeIsSyncTimeZone,
    timeZoneIsSyncItems
  };
};