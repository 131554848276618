import { useState } from 'react';
import { Box, Button, Link } from '@mui/material';
import { AuthInput, BaseBtn, Row, Text } from 'shared/ui';
import { HelloToast } from 'features';
import { useCheckToken, useExtendedTranslation } from 'shared/hooks';
import localStorageService from 'shared/localStorage';
import { UseLogin } from 'features/Mobile/ISS/hooks';

export const LoginForm = () => {
  useCheckToken();
  const { t } = useExtendedTranslation();

  const [isError, setIsError] = useState(false);
  const [authInfo, setAuthInfo] = useState({ userName: '', password: '' });

  const handleChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthInfo((prev) => ({ ...prev, userName: event.target.value }));
  };
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthInfo((prev) => ({ ...prev, password: event.target.value }));
  };

  const { isLoading, login } = UseLogin();

  const onClickSubmit = async () => {
    if (!authInfo.userName || !authInfo.password) {
      setIsError(true);
      return;
    }
    login(authInfo);

    localStorageService.currentUserName = authInfo.userName;
    localStorageService.invalidToken = undefined;
  };

  return (
    <Box component="form" autoComplete="off" sx={{ width: '573px' }}>
      <Text
        sx={{ color: 'primary.main', fontSize: '32px', lineHeight: '48px', fontFamily: 'Noto Sans Hebrew', mb: '10px' }}
      >
        {t('login_title')}
      </Text>

      <AuthInput
        name="login"
        value={authInfo.userName}
        onChange={handleChangeUserName}
        error={isError && !authInfo.userName}
        placeholder={`${t('login_username')}`}
        // autoComplete={'nope'}
      />
      <AuthInput
        name="password"
        value={authInfo.password}
        onChange={handleChangePassword}
        error={isError && !authInfo.password}
        placeholder={`${t('login_password')}`}
        type="password"
        // autoComplete={'nope'}
      />

      <BaseBtn
        onClick={onClickSubmit}
        loading={isLoading}
        fullWidth
        btnColor="primary.main"
        sx={{
          borderRadius: '30px',
          height: '66px',
          fontSize: '24px',
          fontFamily: 'Noto Sans Hebrew',
        }}
      >
        {t('login_button')}
      </BaseBtn>
    </Box>
  );
};
