import { Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import localStorageService from 'shared/localStorage';
import { CustomersPage } from './CustomersPage';
import { SimManagementPage } from './SimManagementPage';
import { ConfigurationPage } from './ConfigurationPage';
import { HomePage } from './HomePage';
import { ControllersPage } from './ControllersPage';
import { RealTimeControllerData } from './RealTimeControllerData';
import { ControllersStatePage } from './ControllersStatePage';
import { BatteryStatusReport } from './BatteryStatusReport';
import { ExternalPowerProblemsReport } from './ExternalPowerProblemsReport';
import { ReplacedEquipmentReport } from './ReplacedEquipmentReport';
import { NetworkProtection } from './NetworkProtection';
import { LoginPage } from './LoginPage';
import { UsersPage } from './UsersPage';
import { useCheckToken } from 'shared/hooks';
import { setGlobalData } from 'shared/slices';
import { useAppDispatch } from 'shared/hooks';
import { Dashboard } from './Dashboard';
import { ControllerDetails } from './ControllerDetails';
import { UnderConstruction } from './UnderConstruction';
import { FotaPage } from './FotaPage';
import { MessagesTemplatesPage } from './MessagesTemplatesPage';
import { WAMessageStatusPage } from './WAMessageStatusPage';
import { ServiceCallsReportPage } from './ServiceCallsReportPage';
import { WeatherStationsPage } from './WeatherStationsPage';
import { TimeZone } from './TimeZone';
import {
  ISSPage,
  ISSLoginPage,
  ISSSiteManagement,
  MaterialListManagement,
  ISSReportManager,
} from '../features/Mobile/ISS';

import { DevTest } from './DevTest';
import { AddSiteMaterial, EditSiteMaterial } from 'features/Mobile/ISS/Components';
export const Routing = () => {
  useCheckToken();
  const dispatch = useAppDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const fetchJson = () => {
      fetch(`./appsettings.json?id=${Date.now()}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          dispatch(setGlobalData(data));
        })
        .catch((e: Error) => {
          console.error(e);
        })
        .finally(() => {
          setIsLoaded(true);
        });
    };
    fetchJson();
  }, []);
  const appRoutes = [
    // { path: '/reset-password', element: <ResetPasswordPage /> },
    { path: '/login', element: <LoginPage /> },
    { path: '/', element: <HomePage /> },
    { path: '/sim-management', element: <SimManagementPage /> },

    { path: '/dashboard', element: <Dashboard /> },
    { path: '/customers', element: <CustomersPage /> },

    { path: '/controllers', element: <ControllersPage /> },
    { path: '/controller-details', element: <ControllerDetails /> },
    { path: '/real-time-controller-Data', element: <RealTimeControllerData /> },

    { path: '/controllers/state', element: <ControllersStatePage /> },

    { path: '/battery-status-report', element: <BatteryStatusReport /> },
    { path: '/external-Power-problems-report', element: <ExternalPowerProblemsReport /> },
    { path: '/replaced-equipment-report', element: <ReplacedEquipmentReport /> },
    { path: '/service-calls-report', element: <ServiceCallsReportPage /> },

    { path: '/network-protection', element: <NetworkProtection /> },

    { path: '/users', element: <UsersPage /> },

    { path: '/configuration/users', element: <ConfigurationPage /> },
    { path: '/configuration/system', element: <ConfigurationPage /> },
    { path: '/configuration/control', element: <ConfigurationPage /> },
    { path: '/configuration/workflow', element: <ConfigurationPage /> },

    { path: '/under-construction', element: <UnderConstruction /> },

    { path: '/fota', element: <FotaPage /> },

    { path: '/configuration/whatsapp/templates', element: <MessagesTemplatesPage /> },
    { path: '/configuration/whatsapp/message-status', element: <WAMessageStatusPage /> },
    { path: '/dev-test', element: <DevTest /> },
    { path: '/weather-station-management', element: <WeatherStationsPage /> },
    { path: '/time-zone', element: <TimeZone /> },
    { path: 'mobile/iss', element: <ISSPage /> },
    { path: 'mobile/iss/login', element: <ISSLoginPage /> },
    { path: 'mobile/iss/site-management', element: <ISSSiteManagement /> },
    { path: 'mobile/iss/material-list-management', element: <MaterialListManagement /> },
    { path: 'mobile/iss/iss-report-manager', element: <ISSReportManager /> },
    { path: 'mobile/iss/iss-report-manager/add', element: <AddSiteMaterial /> },
    { path: 'mobile/iss/iss-report-manager/edit', element: <EditSiteMaterial /> },
  ];

  return (
    <Routes>
      {appRoutes.map((route) => isLoaded && <Route key={route.path} path={route.path} element={route.element} />)}
    </Routes>
  );
};
