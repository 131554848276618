import { Box } from '@mui/material';
import { BaseBtn, Row, Text } from '../../../../shared/ui';
import { useAppSelector, useExtendedTranslation } from 'shared/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useISSData } from '../hooks';
import { BaseSelect } from 'features/BaseSelect';
import { useNavigate } from 'react-router-dom';

export const ISSPage = () => {
  const { t } = useExtendedTranslation();
  const { getISSProjects, getISSSites, getISSPhotos } = useISSData();
  const { ISSProjects, ISSSites, ISSPhoto } = useAppSelector((st) => st.ISSSlice);
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [selectedSite, setSelectedSite] = useState<string>('');
  const push = useNavigate();
  const projectItems = useMemo(
    () =>
      ISSProjects.map((s) => ({
        value: s.id.toString(),
        label: s.projectName,
      })),
    [ISSProjects]
  );
  const siteItems = useMemo(
    () =>
      ISSSites.map((s) => ({
        value: s.id.toString(),
        label: s.siteName,
      })),
    [ISSSites]
  );
  const photos = useMemo(
    () =>
      ISSPhoto.map((s) => {
        return `data:image/jpeg;base64,${s.photoData}`;
      }),
    [ISSPhoto]
  );
  useEffect(() => {
    getISSProjects();
  }, []);
  const onChangeProject = (id: string) => {
    setSelectedProject(id);
    getISSSites(id);
  };
  const onChangeSite = (id: string) => {
    setSelectedSite(id);
    getISSPhotos(id);
  };
  const addSite = () => {
    push('./site-management');
  };

  const viewMaterialList = () => {
    push(`./material-list-management`);
  };

  const editSite = () => {
    push(`./site-management?projectId=${selectedProject}&siteId=${selectedSite}`);
  };
  const generateReport = () => {
    push(`./iss-report-manager`);
  };
  return (
    <Box sx={{ p: { xs: '18px 31px', xl: '58px 61px' } }}>
      <Text
        sx={{
          color: 'primary.main',
          fontFamily: 'Noto Sans Hebrew',
          mb: { xs: '15px', xl: '50px' },
          fontSize: { xs: '41px', xl: '64px' },
          lineHeight: { xs: '46px', xl: '96px' },
          textAlign: 'center',
        }}
      >
        {t('irrigation_site_survey_header_text')}
      </Text>
      <Row>
        <Box>
          <BaseBtn btnColor="primary.dark" onClick={addSite}>
            {t('Add_Site')}
          </BaseBtn>
          <BaseBtn btnColor="primary.dark" onClick={viewMaterialList}>
            {t('view_material_list')}
          </BaseBtn>
          <BaseBtn btnColor="primary.dark" onClick={generateReport}>
            {t('generate_report')}
          </BaseBtn>
          <BaseSelect
            value={selectedProject}
            onChange={onChangeProject}
            items={projectItems}
            hideAllButton
            hideValue
            controlsWidth={200}
            noSelectLabel={`${t('select_project_iss_page')}`}
            sx={{ width: '200px', mb: '16px' }}
          />
          {selectedProject && (
            <Row>
              <BaseSelect
                value={selectedSite}
                onChange={onChangeSite}
                items={siteItems}
                hideAllButton
                hideValue
                controlsWidth={200}
                noSelectLabel={`${t('select_site_iss_page')}`}
                sx={{ width: '200px', mb: '16px' }}
              />
              {selectedSite && (
                <BaseBtn btnColor="primary.dark" onClick={editSite}>
                  {t('Edit_Site')}
                </BaseBtn>
              )}
            </Row>
          )}
          {photos.length && (
            <Row>
              {photos.map((photo, item) => (
                <Box key={`key-${item}`}>
                  <img src={photo} alt="Uploaded" style={{ maxWidth: '300px', height: 'auto' }} />
                </Box>
              ))}
            </Row>
          )}
        </Box>
      </Row>
    </Box>
  );
};
