import { Box } from '@mui/material';
import { Icon, Row, Text } from 'shared/ui';
import { backArrow } from '../../assets';
import { FC, useEffect } from 'react';
import { changeLanguage } from 'i18next';

interface Props {
  title: string;
}

export const Header: FC<Props> = ({ title }) => {
  const handleBack = () => {};
  return (
    <Box width="100%">
      <Box sx={{ height: '40px' }}></Box>
      <Row
        sx={{
          padding: '20px',
          justifyContent: 'space-between',
          position: 'fixed',
          zIndex: '2',
          background: 'white',
          width: '100%',
          top: 0,
        }}
      >
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: '#F5F5F5',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={handleBack}
        >
          <Icon src={backArrow} />
        </Box>
        <Text sx={{ color: 'primary.dark' }}>{title}</Text>
        <Box
          sx={{
            width: '28px',
            height: '28px',
            borderRadius: '50%',
            backgroundColor: 'primary.dark',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text fontSize={'12px'}>AZ</Text>
        </Box>
      </Row>
    </Box>
  );
};
