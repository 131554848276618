import { Box, IconButton, Stack } from '@mui/material';
import { AssignedCustomer } from 'features/WeatherStationsManager/WeatherStationModels';
import { t } from 'i18next';
import { FC } from 'react';
import { close_popup } from 'shared/assets';
import { BaseBtn, BigTitle, Modal, Row } from 'shared/ui';

interface Props {
  openModal: boolean;
  message: string;
  title: string;
  onCloseModal: () => void;
}

export const MessageModal: FC<Props> = ({ openModal, onCloseModal, message, title }) => {
  return (
    <Modal open={openModal} sx={{ margin: 'auto', width: '720px' }} onClose={onCloseModal} padding={'8px 16px'}>
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BigTitle>{title} </BigTitle>
          <IconButton onClick={onCloseModal}>
            <img src={close_popup} />
          </IconButton>
        </Stack>
        <Box sx={{ m: '8px' }}>{message}</Box>
        <Row justifyContent="center">
          <Row width={300} gap="10px">
            <BaseBtn onClick={onCloseModal} fullWidth>
              {t('ok_edit_modal')}
            </BaseBtn>
          </Row>
        </Row>
      </Box>
    </Modal>
  );
};
